@import '../../assets/styles/mixins.module.scss';
@import '../../assets/styles/variables.module.scss';



.container {
    @include page-container-default;
    grid-row: 1/3;

    padding: 0rem;
    height: 92vh;
    min-height: 92vh;
    overflow: hidden;
}

.main {
    width: 100%;
    height: 100%;
    @include flex-column-start-center;
    gap: 0.8rem;
    
    position: relative;
    padding: 0.5rem;

    overflow-y: scroll;

    // border: 5px solid red;
}


.grid_div{
    width:100%;
}

.main_top {
    @include flex-row-between-center;
    width: 100%;
    margin-bottom: 0.5rem;
    @include gray-bottom-border;

}
.main_title {
    @include page-title
}


.main_operation {
    @include flex-row-between-center;
    gap: 1rem;

}



.show_grid_div{
    width: 100%;
    overflow: hidden;
}

.col{
    margin-bottom: 0.5rem;

}

.chart{
    width: 100%;
    
    align-items: center;
    overflow: hidden;

    // border: 1px solid green;
}



// The Padding and Margins values are not equal due to hidding scroll-bar with transform: translateX
.card_container {
    @include flex-row-between-center;
    width: 100%;
    min-height: 6rem;

    overflow: scroll;

    gap: 1rem;
    padding: 2rem 1rem 1.5rem 1rem;

}

.garph_container{
    background-color: $background-color-all-pages;
    border-radius: 0.5rem;


    padding: 0.5rem 0.5rem;
    margin: 1rem 0rem 0.5rem 0rem;

    @include flex-column-start-center;
    gap: 1rem;
    min-height: 650px;
    height: auto;

    overflow-y: scroll;
    overflow-x: hidden;

}




// css related to graphs and charts
.chart_title {
    padding: 2rem 1rem 1rem;

    // font-size: 0.9rem;
    font-weight: bold;

}

.chart {
    min-width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 4px;

}



@media screen and (max-width: 1380px) {
    .top_container {
        justify-content: center;
        flex-wrap: wrap;
    }

}

@media screen and (max-width: 930px) {
    .top_container {
        gap: 1rem;
    }

}

@media screen and (max-width: 900px) and (max-height: 500px) {
    .charts_container {
        overflow-y: hidden;
    }
}


.daterange_selection {
    margin-right: 1em;
    margin-top: 0.5em;
    text-align: center;
    border: 1px solid #d4d4d4;
    padding: 0.5em;
    border-radius: 7px;
    background: #f6f6f6;
    display: flex;
    cursor: pointer;
}
.daterange_selection_date{
    color: #080247;
}







@media screen and (max-width: 767px) {
    .container{
        width: 100vw;
    }

    .main{
        width: 100vw;
        padding: 1rem 0.5rem 2rem;

    }

    // .show_grid_div{
    //     // overflow: hidden;
    // }

    .col{
        width: 100%;

        margin-bottom: 0.5rem;

        // border: 1px solid red;  
    }

    .chart{

        padding-left: 1rem;
    
        align-items: center;
        overflow: hidden;

        // border: 1px solid green;

    }
}


.table {
    @include table-default;
}


.table_div{
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    padding-right: 1rem;
}

