@import '../assets/styles/variables.module.scss';


.custom_tr {
    & th{
        background-color: white;
        z-index: 1;
    }
}


.orange{
    color: orange;
    font-weight: bold;
}
.green{
    color: $green-primary;
    font-weight: bold;
}
.blue{
    color: $color-foreshop;
    font-weight: bold;
}
