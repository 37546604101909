@import '../../../assets/styles/variables.module.scss';

.container {
    // width: 100%;
    width: auto;
    text-align: center;

    border: 2px solid $blue-primary;
    font-weight: bold;
    padding: 0.3rem 0.5rem;
    text-transform: capitalize;
    border-radius: 4px;
    // font-size: 0.9rem !important;

    background-color: white;
    cursor: pointer;
    transition: all 0.3s;

}

.default {
    &:hover {
        color: white;
    }
}


.blue {
    border-color: $blue-primary;
    color: $blue-primary;

    &:hover {
        background-color: $blue-primary;
    }
}

.green {
    border-color: $green-primary;
    color: $green-primary;

    &:hover {
        background-color: $green-primary;
    }
}

.red {
    border-color: $red-primary;
    color: $red-primary;

    &:hover {
        background-color: $red-primary;
    }
}

.orange {
    border-color: orange;
    color: orange;

    &:hover {
        background-color: orange;
    }
}

.reverse {
    color: white !important;
    
    &_blue {
        background-color: $blue-primary;
        box-shadow: 0px 8px 12px $blue-primary;
    }

    &_red {
        background-color: $red-primary;
        box-shadow: 0px 8px 12px $red-primary;
    }

    &_green {
        background-color: $green-primary;
        box-shadow: 0px 8px 12px $green-primary;
    }

    &_orange {
        background-color: orange;
        box-shadow: 0px 8px 12px orange
    }
}

.disabled {
    color: white !important;
    background-color: gray;
    border-color: gray !important;
    cursor: not-allowed !important;

}

@media screen and (max-width: 500px) {

    .container {
        width: unset;
    }
    
}


