@import '../../assets/styles/variables.module.scss';

.container {
    display: flex;
    flex-direction: column;
    // min-height: 9vh;
    max-height: 9vh;

    // border: 1px solid red;
}

.top {
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.7rem;
    box-shadow: 1px 2px 6px 0px #b7b7b7;
    z-index: 5;
}

.image_container {
    display: flex;
    height: inherit;
}

.top_buttons {
    display: flex;
    gap: 0.5rem
}

.bottom {
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
}
.button_container{
    color: #5a5a61;
    margin: auto 10px;
    cursor: pointer;
    // border-right: 1px solid black;
    padding-right: 1em;
}
.button_header {
    // font-size: 14px;
    font-weight: 900;
    color: #182444;
}
.button_text {
    
}
.navbar {
    display: flex;
    align-items: center;
    width: min-content;

    // gap: 0.5rem;
    cursor: pointer;

    & span {

    }

}

.convertion {
    color: $blue-primary;
    font-weight: bold;
    // font-size: 1.8rem;
}

.convertion_date {
    // font-size: 0.9rem;
    color: $gray-primary;
}