@import '../../../assets/styles/variables.module.scss';
@import '../../../assets/styles/mixins.module.scss';

.container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    position: fixed;
    @include flex-row-center-center;

    top: 0;
    left: 0;
    z-index: 1000;

    // border: 1px solid red;
}

.background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $input_background_shade;
}

.main {
    @include popup_main;
    @include flex-column-start-center;

}
.main_center{
    @include popup_main;
    @include flex-column-center-center;
}


.big_main{
    background-color: white;

    min-width: 60vw;
    max-width: 60vw;

    min-height: 400px;
    max-height: 95vh;

    @include flex-column-start-center;
    overflow: hidden;
    
    z-index: 3000;
    padding: 0.5rem !important;
    border-radius: 0.5rem;
    
}






@media screen and (max-width: 767px) {
    .big_main{
        min-width: 95vw;
        max-width: 95vw;
    
        max-height: 95vh;
    }
}



@media screen and (max-width: 500px) {
    .container {
        height: 100vh;
    }

    .main {
        min-width: unset;
    }
}

@media screen and (max-width: 900px) and (max-height: 500px) {
    .main {
        overflow-y: scroll;
    }
}