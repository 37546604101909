@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.main {
    grid-row: 1/3;
}

.main_container{
    min-width: 100vw;
    max-width: 100vw;
    
    @include flex-row-start-start;

    // border: 2px solid rgb(0, 255, 106);
}


.main_page_div{
    width: calc(100vw - 224px);
    min-width: calc(100vw - 224px);
    max-width: calc(100vw - 66px);
    flex-grow: 1;

    overflow: hidden;
    
    // border: 2px solid red;
}

.main_page_div_except_topbarmobile{
    width: 100%;

    height: 94vh;
    overflow-y: scroll;

    // border: 2px solid orangered;
}


.page_div{

    width: 100%;

    height:94vh;
    overflow-y:scroll;


}



// ############################ my custom media queries ############################
// ############################ my custom media queries ############################
@media only screen and (max-width: 767px) {
    .main_container{
        width: 100vw;
        max-width: 100vw;
        @include flex-row-start-start;

        // border: 2px solid orangered;
    }

    .main_page_div{
        min-width: 100vw;
        max-width: 100vw;
        
    }
}
