@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';

.container {
    width: 224px;
    min-width: 66px;
    max-width: 224px;
    height: 100vh;

    @include flex-column-start-center;

    background-color: #182444;

    transition: width 500ms linear 0s;
    overflow:hidden;

    .navbar_container{
        & div{
            font-size: 1.1rem !important;
        }
    }
}

.external_link_image {
    display: flex;
    & img {
        width: 1.3rem;

    }
}

.external_link_name {
    // font-size: 1.1rem;
    color: white;
    white-space: nowrap;
}

.external_link {
    width: 100%;
    height: 45px;

    @include flex-row-start-center;
    padding: 1rem;
    gap: 1rem;

    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        background-color: rgba(255, 255, 255, 0.205);
        border-radius: 0.5rem;
    }
}


.container_minimized {
    grid-row: 1/3;
    position: relative;
    width: 0;

    // transition: width 1s;
    // .logo_container{
    //     .logo{
    //         display: none;
    //     }
    // }
    // .navbar_container{
    //     display: none;
    // }
}

.logo_container {
    background-color: $color-foreshop;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    height: $top-components-height;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $seperator_background;
}

.logo {
    height: 100%;
    width: 100%;
    
    @include flex-row-start-center;
    // border: 3px solid orange;

    & img {
        width: 3rem !important;
    }
    padding: 0.5rem;

    margin-bottom: 0.1rem;
}

.logo_text{
    font-size: 1.1rem !important;
    color: white;

    font-family: 'Open Sans';
    margin-top: 1rem;
    margin-left: 0.5rem;

    & b{
        font-size: 1.1rem;
        color: white;
    }

    // border: 3px solid orange;
}

.small_logo {
    cursor: pointer;
    & img {
        height: 40px;
    }
}

.minimize {
    cursor: pointer;
}


.navbar_container {
    height: 100%;
    background-color: $color-foreshop;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: 0.5rem;
    z-index: 5;
    max-width: 100%;

    padding-top: 1rem;
    // border: 1px solid red;
}

.navbar_container_top{
    padding-top: 0rem;
    width: 224px;
    padding: 0rem 0.5rem;

    background-color: $color-foreshop;
    z-index: 5;
}

.navbar_container_bottom{    
    @include flex-column-center-center;

    padding: 1rem 1rem;
    border-top: 1px solid white;
}

.logout {
    cursor: pointer;
    text-align: center;

}

.navbar_group_btns{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.5rem;
}

.language_container {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
}

.currency {
    position: absolute;
    bottom: 4rem;
    margin-left: 3rem;
    margin-right: 3rem;
    padding: 0.5rem 0.5rem;
    left: 0;
    right: 0;
    text-align: center;
    color:white;
    // font-size: 1.2rem !important;
    border-style: solid;
    border-width: 1px;
    border-radius: 0.1rem;
    // transition: all 0.3s;
    cursor: pointer;

    &:hover {
        background-color: white;
        color: $color-foreshop;
    }

    & img {
        transform: translateY(0.25rem);
    }

}

.minimize_mobile {
    align-self: flex-end;

    & img {
        width: 35px;
    }
}

.impersonate {
    position: absolute;
    bottom: 8rem;
    left: 0;
    right: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    // font-size: 1rem !important;
    color: white;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    padding: 1rem 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blocked_help {
    color: white;
}

.blocked_help_container{
    text-align: center;
}

.terms_of_service_container {
    position: absolute;
    bottom: 14rem;
    left: 0;
    right: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    // font-size: 1rem !important;
    color: white;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    padding: 1rem 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.terms_of_service{
    // font-size: 1rem !important;
    color: white;
}

.terms_link {
    text-align: center;
    width: 100%;
    text-decoration: none;
    // font-size: 1.4em;
    color: black;
}

.terms_link_container {
    text-align: center;
}
.navbar_footer_text {
    color: #afafaf;
    text-align: left;
    width: 100%;
    display: block;

    margin: 1rem 0rem;
    margin-left: 2rem;
    
}

.navbar_footer_text_desktop{
    width: 100%;
    
    color: #afafaf;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 12px;
    margin: 1rem 0rem;
}



@media screen and (max-width: 1600px) {
    .logo_container {
        background-color: $color-foreshop;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        height: $top-components-height;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $seperator_background;
    }

    .logo {
        & img {
            width: 140px;
        }
    }

    .small_logo {
        cursor: pointer;
        & img {
            height: 40px;
        }
    }

    .minimize {
        cursor: pointer;
    }

    .logout {
        cursor: pointer;
        text-align: center;

    }

    .navbar_group_btns{
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 0.5rem;
    }

    .language_container {
        display: flex;
        gap: 0.5rem;
        cursor: pointer;
    }
}

// @media screen and (max-width: 1424px) {
//     .navbar_container{
//         & div{
//             font-size: 1.1rem !important;
//         }
//     }
// }

@media screen and (max-width: 640px) {
.navbar_container_top{
    padding-top: 0rem;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}
}




@media screen and (max-width: 767px) {
    .navbar_container {
        width: 100vw;
        height: 100vh;

        position: fixed;
        top: 0;
        left: 0;

        background-color: #182444;
        background-size: cover;
        
        @include flex-column-start-start;
        padding: 2rem 2rem;        

        z-index: 1000;
        margin-left: 0px;

        overflow-y: scroll;

        // border: 3px solid red;   
    }

    .navbar_container_top_bottom_container{
        width: 100%;
        height: 100%;

        @include flex-column-start-center;

        // border: 2px solid orangered;
    }

    .navbar_container_top_icon_div{
        width: 100%;

        @include flex-row-between-center;

        // font-size: 1rem !important;
        font-weight: bold;
        border-bottom: 1px solid white;

        padding-bottom: 0.8rem;

    }
    .close_icon{
        width: 1.3rem;
        margin-right: 0.5rem;

        // border: 1px solid white;
    }


    .logo{
        height: 100%;
        width: 100%;
        @include flex-row-start-center;

        & img {
            width: 3rem !important;
            // border: 3px solid orange;
        }

        // margin-bottom: 0.5rem;
        padding: 0rem;
        margin: 0rem;
        // border: 3px solid orange;

    }
    .logo_text{
        font-family: 'Open Sans';
        color: white;
        font-size: 1.1rem;

        // margin-left: 0.6em;
    }

    .navbar_container_top{
        padding-top: 0rem;
        width: 100%;
    }



    .external_link {
        width: 100%;
        height: 45px;
    
        @include flex-row-start-center;
        padding: 1rem;
        gap: 1rem;
    
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;
    }

    .navbar_container_bottom{
        padding-top: 0rem;
        margin-top: 0rem;
        width: 100%;

    }
}