@import '../../../assets/styles/variables.module.scss';

.container {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;

    box-shadow: $red-light 0px 0px 15px, $red-light 0px 0px 6px;
}

.red {
    background-color: $red-primary;
    
}

.blue {
    background-color: $blue-primary;
}

.green {
    background-color: $green-primary;
}