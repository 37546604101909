/* league spartan font-face */
@font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-Regular.ttf");
  }
  @font-face {
    font-family: "Open Sans Bold";
    src: url("./assets/fonts/OpenSans-Bold.ttf");
  }
  @font-face {
    font-family: "Open Sans Regular";
    src: url("./assets/fonts/OpenSans-Regular.ttf");
  }
  
  /* Open Sans font-face */
  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-Regular.ttf");
  }
  @font-face {
    font-family: "Open Sans Bold";
    src: url("./assets/fonts/OpenSans-Bold.ttf");
  }


*{
  /* border: 1px dotted rgb(46, 45, 45); */

  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: 'Open Sans Regular';

  color: #182444;
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

::-webkit-scrollbar {
  display: none;
}

.heading{
  font-size: 1.5rem !important;
  font-weight: bold;

  display: flex;
  justify-content: start;
  align-items: center;

  max-width: 25rem;

  overflow-x: scroll;

}

@media only screen and (max-width: 1424px) {
  *{
    font-size: 0.95rem;
  }
}
@media only screen and (max-width: 1360px) {
  *{
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 1199px) {
  .heading{
    max-width: 16rem;
  }
}
@media only screen and (max-width: 991px) {
  .heading{
    min-width: 8rem;
    max-width: 16rem;
  }
}
@media only screen and (max-width: 767px) {
  .heading{
    min-width: 8rem;
  }
}
@media only screen and (max-width: 575px) {
  .heading{
    width: 100%;
  }
}

.react-datepicker-popper {
  z-index: 999999 !important;
}


html {
    overflow: hidden;
    box-sizing: border-box;
}


body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.select-container {
  width: 100%;
  height: 40px;
  max-height: 40px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  background-color: rgb(241, 243, 246);

}

.offer-input{
  border: 10px solid rgb(162, 103, 103);
}

.bold{
  font-weight: bold;
}
.no_data{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: #FF5F9E;
}

