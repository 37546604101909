@import './variables.module.scss';

@mixin gray-bottom-border {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 0.5rem;
    border-bottom-color: $horizontal-line-color;
}

@mixin page-container-default {
    background-color: $input_background_shade_tr;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    // padding-top: $padding-top-all-pages;
    // padding-right: 3rem;
    // padding-left: 3rem;
    // gap: 2rem;
}

@mixin main-top-container-default {
    background-color: white;   
}


@mixin page-title {
    color: $blue-primary;
    font-weight: bold;
    width: auto;
}

@mixin page-title-secondary{
    color: $blue-primary;
    font-weight: bold;
    // font-size: 0.9rem;
    width: auto;
}

@mixin table-header {
    color: $blue-primary;
    font-weight: bold;
    text-align: center;
    padding: 0.8rem !important;
}


@mixin table-default {
    margin-top: 0px !important;
    width: 100%;
    text-align: center;

    & thead th {
        min-width: 2rem;
        max-width: 14rem;

        scroll-padding: 0rem 0rem 0rem 0rem;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        border: 1px solid #dddfe264;
        @include table-header;
        text-align: center;

        padding: 1rem !important;
    }
    & img{
        min-width: 1.3rem;
        max-width: 1.3rem;
        margin: 0rem 0.3rem;
        cursor: pointer;
    }

    & td{
        min-width: 2rem;
        max-width: 14rem;

        overflow-x: scroll;
        text-align: center;
        
        padding: 1rem !important;
        color: $gray-primary;
        border: 1px solid $input_background_shade;
    }
}

// @media screen and (min-width: 1708px) {
// }


// custom mixins
@mixin input_bg{
    background-color: $input_background;
    border-radius: 0.5rem;
    padding: 0rem 0.5rem;
}

@mixin transparent_bg_no_border{
    background-color: transparent;
    border: none;
    outline: none;
}

@mixin sort_icon{
    position: absolute;
    right: 0;
    bottom: 5%;

    width: 1rem;
    height: 1.2rem;

    background-color: white;
    border-radius: 4px;
    padding: 0.2rem;
}


@mixin popup_main{
    background-color: white;
    min-width: 600px;
    max-width: 600px;

    min-height: 400px;
    max-height: 95vh;

    overflow: hidden;
    
    z-index: 3000;
    padding: 0.5rem !important;
    border-radius: 0.5rem;
}

@mixin popup_btn{
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    font-weight: bold;
}

// flex mixins for row
@mixin flex-row-center-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
@mixin flex-row-center-start{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}
@mixin flex-row-center-end{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
@mixin flex-row-start-center{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
@mixin flex-row-start-start{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
@mixin flex-row-start-end{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}
@mixin flex-row-end-center{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
@mixin flex-row-end-start{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}
@mixin flex-row-end-end{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
@mixin flex-row-between-start{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
@mixin flex-row-around-start{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}
@mixin flex-row-evenly-start{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}
@mixin flex-row-between-center{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
@mixin flex-row-around-center{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
@mixin flex-row-evenly-center{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
@mixin flex-row-between-end{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
@mixin flex-row-around-end{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}
@mixin flex-row-evenly-end{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}



// three parameter
@mixin flex-row-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@mixin flex-row-evenly{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
@mixin flex-row-around{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}



// flex mixins for column
@mixin flex-column-center-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@mixin flex-column-center-start{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
@mixin flex-column-center-end{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
@mixin flex-column-start-center{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
@mixin flex-column-start-start{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
@mixin flex-column-start-end{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
@mixin flex-column-end-center{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
@mixin flex-column-end-start{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
@mixin flex-column-end-end{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
@mixin flex-column-between-start{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
@mixin flex-column-around-start{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}
@mixin flex-column-evenly-start{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}
@mixin flex-column-between-center{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
@mixin flex-column-around-center{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
@mixin flex-column-evenly-center{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
@mixin flex-column-between-end{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
@mixin flex-column-around-end{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
}
@mixin flex-column-evenly-end{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
}
// three parameter
@mixin flex-column-between{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@mixin flex-column-evenly{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
@mixin flex-column-around{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
