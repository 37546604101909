@import '../../assets/styles/variables.module.scss';
@import '../../assets//styles/mixins.module.scss';

.container {
    @include page-container-default;
    grid-row: 1/3;
    padding: 0rem;
}

.main {
    width: 100%;
    height: 91vh;

    @include flex-column-between-start;

    background-color: white;
    border-radius: 4px;
    position: relative;
    padding: 1rem 0.8rem;

    overflow-y: hidden;

    // border: 2px solid green;
}

.main_top {
    @include flex-row-between-center;
    width: 100%;
    margin-bottom: 0.5rem;
}


.view_edit_div{
    width: 100%;
    @include flex-row-between-center;
    gap: 5rem;
    margin-top: 1rem;

}
.data_picker_div{
    position: absolute;
    left: 50%;

    transform: translate(-50%);
    z-index: 1000;
}

.each_view_edit_div{
    width: 100%;
    height: 100%;

    @include flex-column-start-center;
}

.main_middle {
    width: 100%;
    height: 100%;
    @include flex-column-start-start;

    overflow-x: hidden;
    overflow-y: hidden;
    
    border-radius: 0.5rem;
}


.table_div{
    width: 100%;
    height: 100%;

    overflow-x: scroll;
    overflow-y: scroll;

    padding-right: 1rem;
}



.main_bottom {
    padding: 0.5rem 0rem;
    @include flex-row-end-center;

    // border: 1px solid cyan;
}


.main_top_extra_padding {
    @include flex-row-between-center;
    width: 100%;
    margin: 1rem 0rem;
    @include gray-bottom-border;
}


.main_title {
    // font-size: 1rem;
    font-weight: bold;

}

.main_title_secondary{
    @include flex-row-center-center;
    @include page-title-secondary;

}

.items_selected_div{
    // font-size: 1rem;
    font-weight: bold;
    color: $gray-primary;
}


.main_search {
    @include flex-row-between-center;
    width: 50%;
    gap: 1rem;
}

.main_operation {
    @include flex-row-between-center;
    gap: 1rem;
}

.filter {
    display: flex;
    gap: 1rem;
}

.filter_checkboxes {
    display: flex;
    gap: 1rem;
    border-style: solid;
    border-width: 2px;
    border-color: $blue-primary;
    padding: 0rem 2rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter_checkboxes_item {
    // font-size: 1rem;
    font-weight: bolddd;
    color: $blue-primary;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}


.go_back_btn_div{
    cursor: pointer;
    text-decoration: underline;
}

.go_back_btn_div:hover{
    color: $red-primary;
    cursor: pointer;
    text-decoration: underline;
}

.go_back_btn{
    padding: 0rem 0.5rem;
}

.td_underline{
    text-decoration: underline !important;
    cursor: pointer;
    font-weight: bold;
}

.td_underline:hover{
    color: $red-primary;
}


.action_tab_buttons{
    @include flex-row-center-center;
    gap:3rem;
    height: auto;
    width: 100%;
}


.input_date{
    background-color: #f3f2f2;
    color: $color-foreshop;
    @include gray-bottom-border;
    padding:1rem;

    border-radius: 0.5rem;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    border-width: 1px;
}

.input_date:focus{
    border-color: rgb(211, 211, 211);

}

.action_button_each{
    cursor: pointer;
}

.action_button_each_inactive{
    cursor: progress;
}

.action_button_each_send_now{
    cursor: pointer;
    width: 12%;
    height: auto;
}

.action_button_each_send_now_sending{
    cursor: progress;
    width: 12%;
    height: auto;
}

.action_button_each_website{
    cursor: pointer;
    padding: 0rem .5rem;
    margin: 0rem 1rem
}

.no_data_found_div{
    @include flex-row-center-center;
    // font-size: 1rem;
    font-weight: bold;
    color: $red-primary;
    margin: 1rem;
    padding: 1rem;
}


a:link {
    color: $blue-primary;
    text-decoration: none;
}
  
a:visited {
    color: $blue-primary;
    text-decoration: none;
}

a:hover {
    color: $red-primary;
    text-decoration: none;
}


.center_div{
    width: 100%;
    height: 100%;
    @include flex-column-center-center;
}
.checkbox {
    cursor: pointer;

    input {
        display: none;

        &+span {
            position: relative;
            float: left;
            width: 1.7rem;
            height: 1rem;

            margin-top: 0.5rem;

            background-color: $red-primary;
            border-radius: 50px;
            transition: all 0.2s ease-in-out;

        }

        &:checked+span {
            background-color: $green-primary;
        }

        &+span:before {
            content: '';
            position: absolute;
            left: 0px;
            height: 100%;
            width: 1.1rem;
            background-color: #fefefe;
            border-radius: 50px;
            transition: all 0.2s ease-in-out;
            box-shadow: inset 5px -5px 10px 5px rgba(255, 255, 255, 0.2),  0 0 4px 0 $red-primary;
        }

        &:checked+span:before {
            left: 0.7rem;

            box-shadow: inset 5px -5px 10px 5px rgba(255, 255, 255, 0.2),  0 0 4px 0 $green-primary;

        }
    }
}


.no_scroll_td{
    padding: 0.5rem;
    overflow: hidden;
    min-height: 100%;

    width: 100%;
    // border: 1px solid red;



    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 0.7rem;

    @include flex-column-center-center;
}
.icon_div{

    width: 100%;
    height: 100%;
    @include flex-row-between-center;
    gap: 0.2rem;

    min-width: 15rem !important;
}

.custom_icon_div{
    min-width: 100%;
    height: 100%;
    
    @include flex-row-center-center;
    gap: 1rem;
    border: none;
}


// pagination search div
.pagination_search_div{
    width: 100%;
    min-height: 6vh;
    max-height: 6vh;

    @include flex-row-between-center;
    overflow: hidden;
}


.margin_top{
    margin-top: 0.5rem;
}
.margin_bottom{
    margin-bottom: 0.5rem;
}
.select_search_item_div{
    @include flex-row-start-center;
    gap: 0.5rem;
    // padding: 0.5rem 0rem;
    
    & select{
        border: 1px solid $input_background;
        border-radius: 4px;
        padding: 0.3rem;
        outline: none;
    }
}
.search_div{
    width: 20%;

    @include flex-row-between-center;
    @include input_bg;

    & input{
        padding: 0.5rem !important;
        @include transparent_bg_no_border;
    }
    & input:focus{
        outline: none;
    }
}
.search_div_30{
    width: 30%;

    @include flex-row-between-center;
    @include input_bg;

    & input{
        padding: 0.5rem !important;
        @include transparent_bg_no_border;
    }
    & input:focus{
        outline: none;
    }
}
.close_div{
    @include input_bg;

    padding: 0.5rem !important;
}
.cursor_pointer{
    cursor: pointer;
}
.no_pointer_event{
    pointer-events: none;
}


// bottom pagination div
.pagination_info_div{
    width: 100%;
    @include flex-row-start-center;
    padding:0.5rem 0rem;
    gap: 0.5rem;
}
.pagination_div{
    width: 100%;
    @include flex-row-end-center;
}
.each_pagination_item{
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid $seperator_background;
}
.each_pagination_item_left_border{
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.each_pagination_item_right_border{
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}
.extra_padding{
    min-width: 2.5rem;

    @include flex-row-center-center;
}
.mark_page_number{
    background-color: $purple-light;
    font-weight: bold;
    color: white;
}







// ########################## popup div classes ##########################
.popup_content_div{
    width: 100%;
    min-height: 320px;

    @include flex-column-between-start;

    padding: 0.5rem;
}

.popup_content_main{
    width: 100%;
    @include flex-row-start-start;
    gap: 0.3rem;
}

.popup_content_main_column{
    width: 100%;
    @include flex-column-start-center;
    gap: 0.3rem;
}
.bottom_buttons{
    width: 100%;
    @include flex-row-end-center;
    gap: 0.5rem;
}

.cancel_btn{
    @include popup_btn;
    background-color: white;
    border: 2px solid $blue-primary;
    color: $blue-primary;
}
.cancel_btn:hover{
    background-color: $blue-primary;
    color: white;
}

.apply_btn{
    @include popup_btn;
    background-color: white;
    border: 2px solid $red-primary;
    color: $red-primary;
}
.apply_btn:hover{
    background-color: $red-primary;
    color: white;
}

.green_btn{
    @include popup_btn;
    background-color: white;
    border: 2px solid $green-primary;
    color: $green-primary;

}
.green_btn:hover{
    background-color: $green-primary;
    color: white;
}





// ######################################################## designs for website control ########################################################
.formAlertDiv{
    color: $red-primary;
  }
  

.creative_image_section{
    width: 100%;
}

.favicon_icon{
    width: 40%;
}




// ######################################################## designs for automation tab ########################################################
.funnel_tier_div{
    @include flex-row-start-start;
    height: 100%;

}


.automation_card{
    @include flex-column-start-start;
    background-color: #f3f2f2;
    border-radius: 1rem;
    padding: 1rem;

}

.daterange_selection {
    margin-right: 1em;
    margin-top: 0.5em;
    text-align: center;
    border: 1px solid #d4d4d4;
    padding: 0.5em;
    border-radius: 7px;
    background: #f6f6f6;
    display: flex;
    cursor: pointer;
}
.daterange_selection_date{
    color: #080247;
}






@media only screen and (max-width: 767px) {
    .search_div{
        width: 50%;
    }
    
    .each_pagination_item{
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid $seperator_background;
    }
}
    
@media only screen and (max-width: 575px) {
    .select_search_item_div{
        @include flex-column-start-center;
    }
}