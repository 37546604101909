@import '../../assets/styles/mixins.module.scss';
@import '../../assets/styles/variables.module.scss';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 100px;
    width: 100%;

    min-width: 150px;
    
    background-color: white;
    gap: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;

    background-color: #fff;
    margin-bottom: 0.5em;

}

.details_container {
    text-align: left;
}

.title {
    // font-size: 0.9rem;
    color: $blue-primary;
    font-family: "Open Sans Bold";

}

.value {
    font-family: "Open Sans Bold";
    // font-size: 0.9rem;
    color: $gray-primary
}

@media screen and (max-width: 930px) {
    .container {
        max-width: unset;
        min-width: unset;
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .title {
        // font-size: 0.9rem;
    }

    .value {
        // font-size: 0.9rem;
    }
}