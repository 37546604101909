@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.container {
    @include page-container-default;
    grid-row: 1/3;
    padding: 0rem;
}

.main {
    height: 100%;
    @include flex-column-start-start;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    padding: 1rem 2rem;
    gap: 0.5rem;
}

.main_center {
    height: 100%;
    @include flex-column-start-center;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    padding: 1rem 2rem;
    gap: 0.5rem;
}

.main_top{
    @include flex-row-between-center;
    // border: 3px solid red;

}
.main_bottom{
    width: 100%;

    @include flex-row-start-start;
    // border: 3px solid blue;

    padding: 0rem !important;
}
.main_bottom_100{
    width: 100%;
}

.main_title {
    @include page-title;
}



.main_middle {
    height: 100%;

    @include flex-column-start-center;
    background-color: $background-color-all-pages;
    
    border-radius: 0.5rem;
}
.tracking_main_middle{
    width: 100%;
    height: 100%;

    @include flex-column-start-center;
    background-color: $background-color-all-pages;
    
    border-radius: 0.5rem;

    // border: 3px solid red;

}

.main_middle_top{
    width: 100%;
}

.main_middle_bottom{
    width: 100%;
}

.main_middle_title{
    padding: 1rem 0.5rem;
    margin-top: 0.5rem;

}



.main_middle_code_div{
    @include flex-row-center-center;
    position: relative;
}


.main_middle_code{
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0.8rem;
    margin: 0rem 0.5rem;
    border-radius: 0.5rem;

    font-family: "Open Sans";

    // code text formatting
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1em;
    min-height: 20em;

}


.main_middle_edit{
    width: max-content;
    float: right;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0.5rem;
}



.edit_code_buttons{
    @include flex-row-end-center;

    width: 100%;
    gap: 1rem;

}




.main_middle_edit_div{
    @include flex-column-center-center;
    background-color: $background-color-all-pages;
    border-radius: 0.5rem;

    padding: 1rem 1rem;
}

// edit popup
.middle_item_div{
    width: 100%;
    @include flex-column-start-start;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.middle_item_label_div{
    @include flex-row-start-center;
    gap: 0.5rem;
    padding: 0.5rem 0.5rem;

}
.middle_item_label{

}

.middle_item_input_div{
    @include flex-row-center-center;
    width: 100%;
}

.middle_textarea{
    min-width: 43rem;
    min-height: 20rem;

    max-width: 100%;
    max-height: 28rem;
    
    background-color: white;
    border: 0px;
    border-radius: 0.5rem;
    padding: 0.8rem;

    font-family: "Open Sans";

}
th{
    font-weight: bold;
}

.textarea_code {
    width: 100%;
    height: 15em;
    resize: none;
    padding: 1em;
    color: #3e3e3e;
}

.form_container{
    width: 100%;
    @include flex-row-start-start;
    gap: 1rem;
}
.form_container_center{
    width: 100%;
    @include flex-row-center-start;
    gap: 1rem;
}

.section_contact_information{
    width: auto;
    background-color: #fff;
    border-radius: 0.5rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    // border: 3px solid blue; // delete

    // overflow-x: scroll;
}
.contact_information{
    min-width: 40rem;
    max-width: 40rem;
    
    margin-top: 0.8rem;
    // border: 3px solid green; // delete
}

// form{
//     // & div{
//         // border: 1px solid green; // delete
//     // }
// }

.top_extra_margin{
    margin-top: 1rem !important;
}

.form_group{
    width: 100%;
    @include flex-row-between-center;

    // border: 3px solid green;
}

.text_left{
    min-width: 35%;
    max-width: 35%;

    font-weight: bold;


    // border: 1px solid red;
}


// .form_group label {
//     display: block;
//     margin-top: 0.5rem;
//     color: #002366;
// }

.form_groups label {
    display: block;
    margin-top: 10px;
    color: #002366;
    display:flex;
}

.form_group_city label {
    display: block;
    margin-top: 10px;
    color: #002366;
    margin-left: 0px !important;
    width: 1px;
}

.form_group select.input-time-zone{
    width: 100%;
    padding: 0.8rem;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    
    background-color: #f1f3f6;

    max-height: 45px;
    height: 100%;
}


.form_group input[type=text]{
    width: 100%;
    padding: 1rem;
    margin-top:10px;
    
    border: none;
    border-radius: 4px;
    background-color: #f1f3f6;

    max-height: 45px;
    height: 100%;
}

.form_group input[type=password] {
    width: 100%;
    padding: 0.8rem;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    
    background-color: #f1f3f6;

    max-height: 30px;
    height: 100%;
}
.form_groups input[type=password] {
    width: 100%;
    padding: 0.8rem;
    margin-top: 0px;
    margin-top:10px;
    border: none;
    border-radius: 4px;
    background-color: #f1f3f6;

    max-height: 45px;
    height: 100%;
}
.form_group_city input[type=text], .form_group input[type=email], .form_group input[type=password] {
    width: 100%;
    padding: 0.8rem;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    
    background-color: #f1f3f6;

    max-height: 45px;
    height: 100%;
}
// .form_city input[type=text], .form_group input[type=email], .form_group input[type=password] {
//     width: 198px;
//     padding: 0.8rem;
//     margin-top: 0px;
//     margin-top:10px;
//     border: none;
//     border-radius: 4px;
//     
//     background-color: #f1f3f6;
//     
//     max-height: 45px;
//     height: 100%;
// }


.form_group input[type=text]:focus, .form_group input[type=email]:focus, .form_group input[type=password]:focus {
    outline: none;
    border-color: #f1f3f6;
    box-shadow: 0 0 0 2px #e9e9ea
}

.input_email,
.input_password,
.input_fullName,
.input_taxId,
.input_adress,
.input_adress2,
.input_city,
.input_state,
.input_country,
.input_zip,
.input_time-zone
   {
/*  background-image: url('img/Frame 1.png'), url('img/Frame 2.png'); */
  background-position: left 0 center; 
  background-repeat: no-repeat; 
  padding-left: 40px; 
  color: #828282;
}



// .input_fullName {
//     background-image: url('../../assets/images/Frame.png'); 
// }
// .input_email {
//   background-image: url('../../assets/images/Frame 1.png'); 
// }
// .input_adress{
//     background-image: url('../../assets/images/address.png'); 
// }
// .input_adress2{
//         background-image: url('../../assets/images/Frame 1 (3).png'); 
// }
// .input_city{
//     background-image: url('../../assets/images/Frame 1 (4).png'); 
// }
// .input_state{
//     background-image: url('../../assets/images/Frame 1 (5).png'); 
// }
// .input_country{
//         background-image: url('../../assets/images/Frame 1 (6).png'); 
// }
// .input_zip{
//     background-image: url('../../assets/images/Frame 1 (7).png'); 
// }




.success_msg{
    color: red;

    align-items: center;
    margin-left: 3px;
    margin-top: 10px;
}
.error_msg{
    color: red;
    
    align-items: center;
    margin-left: 3px;
    margin-top: 10px;
}

 .line {
    border: 1px solid #f1f3f6;
    width: 100%;
  }
   .line_password {
    margin: 0.5rem 0rem;
    border: 1px solid #f1f3f6;
    width: 100%;
  }
   .contact_information_text p,
   .profile_settings_text p,
    .password_text p{
       color: #7E7E7E;
       
       text-align: center;
       margin: 2rem;
  }

  .contact_information_title,
   .profile_settings_title,
    .password_title{
        // border: 1px solid;
  }


.section_profile_settings,
.section_password{
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;

}  

.section_contact_information h2,
.section_profile_settings h2,
.section_password h2{

    font-weight: bold;
    color: #002366;
    margin: 0;
  }  


.breadcrumb_title h2{
    max-width: 1170px;
    color: #002366;
    font-weight: bold;

    margin: 0rem !important;
} 
.breadcrumb_title {
    display: flex;
    justify-content: space-between;
    // margin-bottom: -30px;
}
// .breadcrumb_title h2 {
//     // margin-bottom: 1rem;
// }

.form_row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 1em;
    align-items: center;
    margin: 0 auto;


}
.form_rows{
    width: 100%;
    @include flex-row-between-center;
    gap: 1rem;


    & div{
        width: 100%;
    }
}

.form_rows_each{
    width: 100%;
    height: 100%;

    

    // border: 3px solid orangered;


    & input[type=text]{
        width: 100%;
        height: 100%;
        max-height: 45px;
    
        padding: 1rem;
    
        border: none;
        border-radius: 4px;
        
        background-color: #f1f3f6;
        
    }

    & select{
        width: 100%;
        height: 100%;
        max-height: auto;
    
        padding: 0.8rem;
    
        border: none;
        border-radius: 4px;
        
        background-color: #f1f3f6;
           
    }
}

.form_city input[type=text]{ 
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 45px;

    padding: 1rem;
    margin-top: 0px;
    margin-top:10px;

    border: none;
    border-radius: 4px;
    
    background-color: #f1f3f6;
    
}


.form_select {
    width: 100%;
    height: 100%;
    max-height: 45px;

    padding: 0.7rem;
    margin-top: 10px;

    border: none;
    border-radius: 4px;
    // padding-left: 3rem;
    padding-right: 1rem;
    background-color: #f1f3f6;
    

    // border: 1px solid red;
}

.btn_ubmit {
    width: 100%;
    margin: 1rem auto;
    border: none;
    border: 2px solid $blue-primary;
    background-color:white;
    color: #fff;
    max-width: 150px;
    letter-spacing: 1.5px;
    border-radius: 25px;
}


.btn_submit_div{
    width: 100%;
    @include flex-row-end-center;
}
.btn_submit button, .aside_btn button{
    width: auto;
    padding: 0.3rem 0.5rem;

    cursor: pointer;
    max-width: 140px;
    margin-bottom:0.5rem;
    margin-top: 0.5rem;

    // text-transform: capitalize;
    border-radius: 4px;
    background-color: white ;
    border: 2px solid $blue-primary;

    color: $blue-primary;
    font-weight: bold;
}

.btn_submit img{
    cursor: pointer;
}

.btn_submit button:hover{
    color: #fff;
    background-color: $blue-primary;
    border: 2px solid $blue-primary;
}




.section_password{
    background-color: #fff;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    margin-left: 69px;

    // border: 1px solid blue;
}
.success_msg{
    color: rgb(2, 208, 2);
}
.error_msg{
     color:red;
}
.form_row{
    color:red;
    margin-left: 0px !important;
    width: 1px;
}
.text_left_label{
    margin-left:0px;
    display: flex;
    margin-top: 10px;
}
.pswd_label {
    display:flex;
    color:red;
}



@media only screen and (max-width: 1600px) {
//     .form_container{
//         padding-top: 1rem;
        
//         margin-left: 0px !important;
//    }

   .main {
        height: 100%;
        @include flex-column-between;
        background-color: rgb(255, 255, 255);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: relative;
        padding: 1rem 2rem;
        gap: 0.5rem;

    }
    .form_group{
        width: 100%;
        @include flex-row-between-center;
    
        // border: 2px solid blue
    }
    
    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top:10px;
        
        border: none;
        border-radius: 4px;
        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
        // .form_city input[type=text], .form_group input[type=email], .form_group input[type=password] {
        // width: 243px;
        // padding: 0.8rem;
        // margin-top: 0px;
        // margin-top:10px;
        // border: none;
        // border-radius: 4px;
        // 
        // background-color: #f1f3f6;
        // 
        // max-height: 45px;
        // height: 100%;
        // margin-left:-12px;
    // }
    // .form_rows .form_select {
    //     width: 227px;
    //     padding: 13px;
    //     margin-top: 0px;
    //     margin-bottom: 10px;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     margin-right:35px;
    // }
    .form_groups input[type=password] {
    width: 100%;
    padding: 0.8rem;
    margin-top: 0px;
    margin-top:10px;
    border: none;
    border-radius: 4px;
    background-color: #f1f3f6;
    
    max-height: 45px;
    height: 100%;
}
.section_password{
    background-color: #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        margin-left: 190px;

}
   
}

@media only screen and (max-width: 1424px) {
   .main {
        height: 100%;
        @include flex-column-between;
        background-color: rgb(255, 255, 255);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: relative;
        padding: 1rem 2rem;
        gap: 0.5rem;

    }
    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top:10px;
        
        border: none;
        border-radius: 4px;
        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
    //     .form_city input[type=text], .form_group input[type=email], .form_group input[type=password] {
    //     width: 193px;
    //     padding: 0.8rem;
    //     margin-top: 0px;
    //     margin-top:10px;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    // }
    // .form_rows .form_select {
    //     width: 193px;
    //     padding: 13px;
    //     margin-top: 0px;
    //     margin-bottom: 10px;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     margin-right:24px;
    // }
    .form_groups input[type=password] {
    width: 100%;
    padding: 0.8rem;
    margin-top: 0px;
    margin-top:10px;
    border: none;
    border-radius: 4px;
    background-color: #f1f3f6;
    
    max-height: 45px;
    height: 100%;
}
.section_password{
    background-color: #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        margin-left: 190px;

}

}



@media only screen and (max-width: 1280px) {
//     .form_container{
//         padding-top: 1rem;
        
//         margin-left: 0px !important;
//    }
   .main {
        height: 100%;
        @include flex-column-between;
        background-color: rgb(255, 255, 255);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: relative;
        padding: 1rem 2rem;
        gap: 0.5rem;

    }
    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top:10px;
        
        border: none;
        border-radius: 4px;
        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
        // .form_city input[type=text], .form_group input[type=email], .form_group input[type=password] {
    //     width: 200px;
    //     padding: 0.8rem;
    //     margin-top: 0px;
    //     margin-top:10px;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    // }
    // .form_rows .form_select {
    //     width: 200px;
    //     padding: 13px;
    //     margin-top: 0px;
    //     margin-bottom: 10px;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    // }
    .form_groups input[type=password] {
    width: 100%;
    padding: 0.8rem;
    margin-top: 0px;
    margin-top:10px;
    border: none;
    border-radius: 4px;
    background-color: #f1f3f6;
    
    max-height: 45px;
    height: 100%;
}
.section_password{
    background-color: #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        margin-left: 190px;

}

}

@media only screen and (max-width: 1024px) {
//     .form_container{
//         padding-top: 1rem;
        
//         margin-left: 0px !important;
//    }
   .main {
        height: 100%;
        @include flex-column-between;
        background-color: rgb(255, 255, 255);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: relative;
        padding: 1rem 2rem;
        gap: 0.5rem;

    }

    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top: 0px;
        margin-top:10px;
        border: none;
        border-radius: 4px;

        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
    //     .form_city input[type=text], .form_group input[type=email], .form_group input[type=password] {
    //     width: 150px;
    //     padding: 0.8rem;
    //     margin-top: 0px;
    //     margin-top:10px;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    // }
    // .form_rows .form_select {
    //     width: 150px;
    //     padding: 13px;
    //     margin-top: 0px;
    //     margin-bottom: 10px;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    // }
    .form_groups input[type=password] {
    width: 100%;
    padding: 0.8rem;
    margin-top: 0px;
    margin-top:10px;
    border: none;
    border-radius: 4px;
    background-color: #f1f3f6;
    
    max-height: 45px;
    height: 100%;
}
.section_password{
    background-color: #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        margin-left: 190px;

}
   .line_password {
    border: 1px solid #f1f3f6;
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
//     .form_container{
//         padding-top: 1rem;
        
//         margin-left: 0px !important;
//    }
   .main {
        height: 100%;
        @include flex-column-between;
        background-color: rgb(255, 255, 255);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: relative;
        padding: 1rem 2rem;
        gap: 0.5rem;
    }

    .section_contact_information{
        width: 100%;
    }

    .contact_information{
        min-width: 100%;
        max-width: 100%;

    }

    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top: 0px;
        margin-top:10px;
        border: none;
        border-radius: 4px;

        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
    //     .form_city input[type=text], .form_group input[type=email], .form_group input[type=password] {
    //     width: 130px;
    //     padding: 0.8rem;
    //     margin-top: 0px;
    //     margin-top:10px;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    // }
    // .form_rows .form_select {
    //     width: 143px;
    //     padding: 13px;
    //     margin-top: 0px;
    //     margin-bottom: 10px;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     width: 122px;
    // }
    .form_groups input[type=password] {
    width: 100%;
    padding: 0.8rem;
    margin-top: 0px;
    margin-top:10px;
    border: none;
    border-radius: 4px;
    background-color: #f1f3f6;
    
    max-height: 45px;
    height: 100%;
    }
    .section_password{
        background-color: #fff;
            margin-bottom: 1rem;
            padding-bottom: 0.5rem;
            margin-left: 117px;

    }
   .line_password {
    border: 1px solid #f1f3f6;
    width: 100%;
  }
//   .form_rows {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     grid-column-gap: -5px;
//     grid-row-gap: 5px;
//     align-items: center;
// }
}

@media only screen and (max-width: 767px) {
    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top: 0px;
        margin-top:10px;
        border: none;
        border-radius: 4px;
        
        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
     .form_groups input[type=password] {
        width: 100%;
        padding: 0.8rem;
        margin-top: 0px;
        margin-top:10px;
        border: none;
        border-radius: 4px;
        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
    // .form_city{
    // margin-left: 108px;
    // }
//    .form_container{
//         padding-top: 1rem;
//          flex-direction: column;
//         margin-left: 0px !important;
//    }
    // .form_rows{
    //     display: flex;
    //     flex-direction: column;
    //     margin-left: 0px !important;
    // }
    // .form_city input[type=text], .form_group input[type=email], .form_group input[type=password] {
    // width: 500px;
    // padding: 0.8rem;
    // border: none;
    // border-radius: 4px;
    // 
    // background-color: #f1f3f6;
    // 
    // max-height: 45px;
    // height: 100%;
   
    // }
    .line {
    border: 1px solid #f1f3f6;
    width: 493px;
  }

    // .form_rows .form_select {
    // width: 500px;
    // padding: 0.8rem;
    // border: none;
    // border-radius: 4px;
    // 
    // background-color: #f1f3f6;
    // 
    // max-height: 45px;
    // height: 100%;
    // }

    // .btn_submit button, .aside_btn button{
    //     width: 100%;
    //     padding: 6px;
    //     cursor: pointer;
    //     max-width: 140px;
    //     margin-top:20px;
    //     text-transform: capitalize;
    //     border-radius: 25px;

    //     background-color: white;
    //     border: 2px solid $blue-primary;
    //     color: $blue-primary;

    //     margin-right:-56px;
    //     margin-bottom: 30px;
    // }
    .section_password{
        background-color: #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 640px) {
//     .form_container{
//         padding-top: 1rem;
//          flex-direction: column;
//         margin-left: 0px !important;
//    }
//    .form_rows {
       
//         grid-template-columns: repeat(2, 1fr);
//         grid-column-gap: 102px;
//         grid-row-gap: 7px;
//         align-items: center;
//         width: 425px;
//     }
//    .form_groups input[type=password] {
//         width: 402px;
//         padding: 0.8rem;
//         margin-top: 0px;
//         margin-top:10px;
//         border: none;
//         border-radius: 4px;
//         background-color: #f1f3f6;
//         
//         max-height: 45px;
//         height: 100%;
//     }
    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top: 0px;
        margin-top:10px;
        border: none;
        border-radius: 4px;
        
        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
   
    // .form_city input[type=text] {
   
    //     padding: 0.8rem;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     display: flex;
    //      margin-right:116px;
    //     width: 402px;
    // }

    // .form_rows .form_select {
    //     padding: 0.8rem;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     width: 402px;
    //     margin-right: 123px;
    // }

    // .btn_submit button, .aside_btn button{
    //     width: 100%;
    //     padding: 6px;

    //     cursor: pointer;
    //     max-width: 140px;
    //     text-transform: capitalize;

    //     background-color: white;
    //     border: 2px solid $blue-primary;
    //     color: $blue-primary;

    //     border-radius: 25px;
    //     margin-right:-31px;
    //     margin-bottom:36px;
    // }
    .section_password{
        background-color: #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 480px) {
//       .form_container{
//         padding-top: 1rem;
//          flex-direction: column;
//         margin-left: 0px !important;
//    }
//    .form_rows {
       
//         grid-template-columns: repeat(2, 1fr);
//         grid-column-gap: 102px;
//         grid-row-gap: 7px;
//         align-items: center;
//         width: 425px;
//     }
//    .form_groups input[type=password] {
//         width: 349px;
//         padding: 0.8rem;
//         margin-top: 0px;
//         margin-top:10px;
//         border: none;
//         border-radius: 4px;
//         background-color: #f1f3f6;
//         
//         max-height: 45px;
//         height: 100%;
//         margin-right: 139px;
//     }
    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top: 0px;
        margin-top:10px;
        border: none;
        border-radius: 4px;
        
        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
   
    // .form_city input[type=text] {
   
    //     padding: 0.8rem;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     display: flex;
    //      margin-right:171px;
    //     width: 353px;
    // }

    // .form_rows .form_select {
    //     padding: 0.8rem;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     width: 353px;
    //     margin-right: 186px;
    // }

    // .btn_submit button, .aside_btn button{
    //     width: 100%;
    //     padding: 6px;

    //     cursor: pointer;
    //     max-width: 140px;

    //     border-radius: 25px;
    //     margin-right:62px;
    //     margin-bottom:5px;
    // }
    .section_password{
        background-color: #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 375px) {
//      .form_container{
//         padding-top: 1rem;
//          flex-direction: column;
//         margin-left: 0px !important;
//    }
//    .form_rows {
       
//         grid-template-columns: repeat(2, 1fr);
//         grid-column-gap: 102px;
//         grid-row-gap: 7px;
//         align-items: center;
//         width: 425px;
//     }
//    .form_groups input[type=password] {
//         width: 253px;
//         padding: 0.8rem;
//         margin-top: 0px;
//         margin-top:10px;
//         border: none;
//         border-radius: 4px;
//         background-color: #f1f3f6;
//         
//         max-height: 45px;
//         height: 100%;
//         margin-right: 143px;
//     }
    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top: 0px;
        margin-top:10px;
        border: none;
        border-radius: 4px;
        
        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
   
    // .form_city input[type=text] {
   
    //     padding: 0.8rem;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     display: flex;
    //      margin-right:274px;
    //     width: 250px;
    // }

    // .form_rows .form_select {
    //     padding: 0.8rem;
    //     border: none;
    //     border-radius: 4px;
    //     
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     width: 253px;
    //     margin-right: 284px;
    // }

    // .btn_submit button, .aside_btn button{
    //     width: 100%;
    //     padding: 6px;

    //     cursor: pointer;
    //     max-width: 140px;
    //     text-transform: capitalize;

    //     border-radius: 25px;

    //     margin-right:150px;
    //     margin-bottom:5px;
    // }
    .section_password{
        background-color: #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 320px) {
    .form_group input[type=text]{
        width: 100%;
        padding: 1rem;
        margin-top: 0px;
        margin-top:10px;
        border: none;
        border-radius: 4px;
        
        background-color: #f1f3f6;
        
        max-height: 45px;
        height: 100%;
    }
    //  .form_groups input[type=password] {
    //     width: 245px;
    //     padding: 0.8rem;
    //     margin-top: 0px;
    //     margin-top:10px;
    //     border: none;
    //     border-radius: 4px;
    //     background-color: #f1f3f6;
    //     
    //     max-height: 45px;
    //     height: 100%;
    //     margin-right: 155px;
    // }
//    .form_container{
//         padding-top: 1rem;
//          flex-direction: column;
//         margin-left: 0px !important;
//    }
    // .form_rows{
    //     display: flex;
    //     flex-direction: column;
    //     margin-left: 0px !important;
    // }
    // .form_city input[type=text], .form_group input[type=email], .form_group input[type=password] {
    // width: 191px;
    // padding: 0.8rem;
    // border: none;
    // border-radius: 4px;
    // 
    // background-color: #f1f3f6;
    // 
    // max-height: 45px;
    // height: 100%;
    // margin-right:352px;
    // }

    // .form_rows .form_select {
    // width: 191px;
    // padding: 0.8rem;
    // border: none;
    // border-radius: 4px;
    // 
    // background-color: #f1f3f6;
    // 
    // max-height: 45px;
    // height: 100%;
    // margin-right:361px;
    // }

    .btn_submit button, .aside_btn button{
        margin-top:20px;
        margin-right:219px;
    }
    .section_password{
        background-color: #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        margin-left: 0px;
    }
}










// ############################ my custom media queries ############################
// ############################ my custom media queries ############################
@media only screen and (max-width: 767px) {
    .main{
        width: 100%;
        padding: 0.8rem;

        // border: 1px solid red;
    }

    .form_container{
        width: 100%;
        @include flex-column-around-start;
        gap: 2rem;
    }


    .section_contact_information{
        width: 100%;

        // border: 3px solid red; // delete

        & div{
            width: 100%;
        }

    }

    .contact_information{
        min-width: 100%;
        max-width: 100%;

        // border: 3px solid rgb(118, 118, 0); // delete
    }

    .form_group{
        width: 100%;
        @include flex-column-start-start;    
        // border: 1px solid green;
    }

    .text_left{
        margin-top: 0.8rem;

        min-width: 100%;
    
        font-weight: bold;
    
        // border: 1px solid orangered;

    }

    .btn_submit_div{
        text-align: end;
    }
    
}