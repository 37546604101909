@import "../../../assets/styles/variables.module.scss";
@import "../../../assets/styles/mixins.module.scss";

.main {
  position: relative;
  overflow: hidden;
  line-height: 1.5;

  width: 100%;
  height: 100%;
  min-height: 400px;

  @include flex-column-start-start;

  // border: 1px solid blue;
}

.main_top {
  width: 100%;

  font-weight: bold;
  margin-left: 0px;
  margin-right: 0px;
  border-bottom: 1px solid #dddfe2;
  margin-bottom: 5px;

  padding: 0.8rem 0.5rem;
  @include flex-row-between-center;

  position: relative;

  // border: 1px solid red;

  & img {
    cursor: pointer;
    position: absolute;
    right: 0rem;

  }

  & span {
    font-size: 1.5rem;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
  }
}



.main_bottom{
  width: 100%;
  overflow-y: scroll !important;

  // border: 1px solid blueviolet;


}
