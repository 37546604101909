/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
    width: 100%;
}

.responsiveTable td .tdBefore {
    display: none;
}


@media screen and (max-width: 1600px) {
    /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */
    .header_button {
        background-color: transparent;
        margin-left: 0px;
    }
  
    .responsiveTable thead tr {
       margin: 0px !important;      
    }
    /* .responsiveTable tbody tr {
        border: 1px solid red;
        padding: .25em;
    } */
}


@media screen and (max-width: 1424px) {
    /*
        Force table elements to not behave like tables anymore
        Hide table headers (but not display: none;, for accessibility)
    */
   
    .header_button {
        background-color: transparent;
        margin-left: 0px;
    }

    .responsiveTable thead tr {
        margin: 0px !important;        
    }

    .responsiveTable tbody tr {
        padding: 1px;
        /* border: 1px solid red; */
    }
}


/* @media screen and (max-width: 1360px) { */
    /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  /* .responsiveTable table{
    display: flex;
    flex-direction: row;
} */

    /* .header_button {
        background-color: transparent;
        margin-left: 0px;

    }
    .responsiveTable thead{
        border: 2px solid #F1F3F6;

    }
    .responsiveTable thead tr {
    margin: 0px !important;      
    
    }
    .responsiveTable tbody tr {
        border: 1px solid red;
        padding: .25em;
    } */
    

    /* .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
        display: block;
    }

    .responsiveTable thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
        border-bottom: 2px solid #333;
        
    }

    .responsiveTable tbody tr {
        width: 100%;
        border: 1px solid green;
;
        padding: .25em;
    }

    .responsiveTable td.pivoted {
        border: none !important;
        position: relative;
        padding-left: calc(50% + 10px) !important;
        text-align: left !important;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        margin-bottom: 10px;
        min-height: 20px;
    }

    .responsiveTable td .tdBefore {
        position: absolute;
        display: block;

        Top/left values mimic padding
        left: 1rem;
        width: calc(50% - 20px);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        text-align: left !important;
        font-weight: 600;
    } */
/* } */

/* @media screen and (max-width: 767px) { */
    /* .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody{
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        justify-content: start;
        align-items: start;

    }
    .responsiveTable th, td{
        min-width: 10rem;
        max-width: 10rem !important;

        overflow-x: scroll;
    } */
/* 
    .responsiveTable thead tr {
        /* position: absolute;
        top: -9999px;
        border-bottom: 2px solid #333;


    } */

    /* .responsiveTable tbody tr {
        width: 100%;
        border: 1px solid red;

        padding: .25em;
    } */

    /* .responsiveTable td.pivoted { */
        /* Behave like a "row" */
        /* border: none !important;
        position: relative;
        padding-left: calc(50% + 10px) !important;
        text-align: left !important;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        margin-bottom: 10px;
        min-height: 20px; */

    /* } */

    /* .responsiveTable td .tdBefore {

        position: absolute;
        display: block;

        left: 1rem;
        width: calc(50% - 20px);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        text-align: left !important;
        font-weight: 600;
    } */

/* } */

/* @media screen and (max-width: 480px) {
   .responsiveTable td.pivoted {
       border: none !important;
        position: relative;
        padding-left: calc(50% + -50px) !important;
        text-align: left !important;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        margin-bottom: 10px;
        min-height: 20px;
        margin-right: 0px !important;
        width: 397px;
    }

}


@media screen and (max-width: 375px) {
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
        display: block;
        
    }
    .devices span img {
        width: 20px;
        margin-right: 1px;
    }
    .devices span {

        font-weight: bold;
        width: 35px;
    }


    .responsiveTable thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
        border-bottom: 2px solid #333;
        
    }

    .responsiveTable tbody tr {
        padding: .25em;
    }

    .responsiveTable td.pivoted {
       border: none !important;

    padding-left: calc(50% + -42px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    margin-bottom: 10px;
    min-height: 20px;
    display: flex;
    flex-direction: column;

    }

    .responsiveTable td .tdBefore {

        position: absolute;
        display: block;


        left: 1rem;
        width: calc(50% - 20px);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        text-align: left !important;
        font-weight: 600;
    }
} */



.custom_select_wrapper {
    min-width: 100%;

    background: none;
    border: none;
    padding: 0rem;
    margin: 0rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom_select_wrapper .label,
.custom_select_wrapper > div:first-child {
    min-width: 30%;
    max-width: 30%;
    
    text-align: left;
    font-weight: bold;

}



.custom_select_wrapper input[type="text"],  
.custom_select_wrapper input[type="number"],  
.custom_select_wrapper select {
    min-width: 70%;
    max-width: 70%;

    text-align: left;
    background: #f7f7f7;
    border-radius: 4px;
    
    padding: 0.7rem 0.5rem;
}



.custom_select_wrapper .multi-select{ 
    min-width: 70%;
    max-width: 20rem;

    text-align: left;
    border-radius: 4px;

    border-style: none;
    outline: none;

    color: rgb(55, 73, 87);
    background: #f7f7f7 !important;

    position: relative;
} 

.search{
    min-width: 100%;
    /* background-color: white; */
}
.search input{
    min-width: 90% !important;
    background-color: white !important;
}

.options li:hover{
    background-color: #f7f7f7 !important;

}
/* 
.select-item::selection{
}
 */


.form-list {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

}

.form-list > ul {
    list-style: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;

}

.form-list > ul > li {
    display: flex;
    margin-right: 10px;
}

.form-list > ul > li input {
    margin-right: 5px;
    margin-top: 0rem;
    padding: 0.5rem;
    
}


.devices_parent_div{
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* border: 1px solid red; */
}
.devices_div{
    width: 10rem;
    /* max-width: 10rem; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    
    /* border: 1px solid blue; */
}

.devices {
    width: auto;
    height: 30px;   

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0.2rem;

    padding: 0rem 0.5rem;
}


.devices span {
    font-weight: bold;
    width: 40px;
}

.devices img {
    max-width: 1.2rem;
    min-width: 1.2rem;
    margin-right: 0.5rem;

    cursor: pointer;
}

.devices span img {
    max-width: 1.2rem;
    min-width: 1.2rem;
    margin-right: 0.5rem;
}

.devices ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.devices ul li {
    margin: 0 5px;
}

.devices ul li img {
    width: 15px;
}


.countries_parent_div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.countries {
    min-width: 12rem;
    max-width: 12rem;
    flex-wrap: wrap;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* border: 1px solid blue; */
}
.countries_left{
    min-width: 12rem;
    max-width: 12rem;
    flex-wrap: wrap;
    
    display: flex;
    justify-content: start;
    align-items: center;
}

.countries img, .countries_left img {
    max-width: 1.2rem;
    min-width: 1.2rem;
    cursor: pointer;
    margin-right: 0.5rem;
}

.label:after {
    content: " *";
    color: red; 
}
