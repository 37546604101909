@import '../../../assets/styles/variables.module.scss';

.container {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-blend-mode: multiply;
}

.main {
    min-width: 50vw;
    min-height: 50vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
    padding: 2.5rem 2rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}


.title{
    // font-size: 10rem;
}

.subtitle{
    // font-size: 0.9rem;
    color: $red-primary;
}

.login-btn-div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    padding-top: 5rem;
}

