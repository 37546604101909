$background-color-all-pages: #f2f2f2;
$color-foreshop: #182444;
$blue-primary: #182444;
$blue-secondary: #3F0071;
$blue-secondary-shade: #3e007197;

$purple-light: #3e007197;

$red-primary: #E90064;
$red-secondary: #B3005E;
$red-light: #FF5F9E;

$green-primary: #02cd19;
$green-primary-shade: #02cd1ac7;
$yellow-primary: #ebd400;

$gray-primary: #374957;
$gray-secondary: #374957ab;
$horizontal-line-color: #EFF1F4;
$input_background: #F1F3F6;
$input_background_shade: rgb(241, 243, 246, 0.8);
$input_background_shade_tr: rgb(241, 243, 246, 0.7);
$seperator_background: rgba(220, 220, 220, 0.452);
// $input_background_shade: rgba(220, 220, 220, 0.8);


$padding-top-all-pages: 3rem;
$top-components-height: 78px;

// Tables Sizes
$table-height-default: 520px;
$table-max-height-960: 480px;
$table-max-height-910: 430px;
$table-max-height-860: 380px;
$table-max-height-810: 330px;
$table-max-height-760: 280px;
$table-max-height-710: 230px;
$table-max-height-660: 224px;
$table-max-height-610: 130px;
$table-max-height-560: 80px;