@import '../../assets/styles/variables.module.scss';

.container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 9000;
    // background-image: url('../../assets/images/bg.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    width: 440px;
    height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 2rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}

.top_container {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    // margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
}

.logo {
    width: 234px;

    img {
        width: 100%;
    }
}

.logo_text{
    font-style: bold;
    font-weight: 800;
    color: $blue-primary;
    // font-size: 1.4em;
    text-align: center;

}

.title {
    // font-size: 1.8rem;
    font-weight: bold;
}

@media screen and (max-width: 500px) {

    .container {
        background-image: unset;
    }

    .main {
        height: 56vh;
        width: 96vw;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
    }
}

// Spinner
.spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;

  }
  .spinner div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: $color-foreshop;
    animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .spinner div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .spinner div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .spinner div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes spinner {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  