@import '../../../assets/styles/variables.module.scss';
@import '../../../assets/styles/mixins.module.scss';

.container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    position: fixed;
    @include flex-row-center-center;

    top: 0;
    left: 0;
    z-index: 1000;
}

.background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $input_background_shade;
}

.main {
    @include flex-column-start-center;

}






@media screen and (max-width: 500px) {
    .container {
        height: 100vh;
    }

    .main {
        min-width: unset;
    }
}

@media screen and (max-width: 900px) and (max-height: 500px) {
    .main {
        overflow-y: scroll;
    }
}