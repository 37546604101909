@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';

.container {
    @include page-container-default;
    grid-row: 1/3;
    padding: 0rem;
}

.main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 0 3px rgba(222, 225, 229, 0.3);
    position: relative;

    padding: 0rem 0.8rem;
    padding-bottom: 0.5rem;
    margin-top: 0rem;
    overflow-y: hidden;

    // border: 2px solid black;
}

.main_top {
    @include flex-row-between-center;
    margin-bottom: 0.5rem;

}

.main_title {
    // @include page-title

    font-weight: bold;
}

.main_search {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.main_top_right {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
}



.main_bottom {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.search_container {
    grid-row: 1/2;
    grid-column: 1/2;
    width: 30%;
    display: flex;
    gap: 1rem;
    padding: 0.5rem 0.5rem;
    background-color: $background-color-all-pages;
    color: #374957;

    & input {
        border-style: none;
        outline: none;
        background-color: $background-color-all-pages;
        color: #374957;

    }
}

.amountselect {
    justify-self: end;
    grid-row: 1/2;
    grid-column: 2/3;
}


.website_details_container {
    grid-row: 2/3;
    grid-column: 1/3;
    display: flex;
    justify-content: space-between;
    color: $blue-primary;

    padding: 0px;
}


.buttons {
    display: flex;
    justify-content: space-between;
    gap: 1rem
}

.buttons_right {
    width: 550px;
    display: flex;
    gap: 1rem;
}


// .table_data {
    // & tr:last-child td div {
    //     top: -15rem;
    // }

    // & tr:nth-last-child(2) td div {
    //     top: -15rem;
    // }

    // & tr:nth-last-child(3) td div {
    //     top: -15rem;
    // }

    // & tr:first-child td div {
    //     top: 5rem !important;

    // }

    // & tr:nth-child(2) td div {
    //     top: 5rem !important;
    // }

    // & tr:nth-child(3) td div {
    //     top: 5rem !important;

    // }

// }


.td_underline {
    text-decoration: underline !important;
    cursor: pointer;
    font-weight: bold;
}

.td_underline:hover {
    color: $red-primary;
}


.main_middle_filter_div {
    @include flex-column-center-center;
    background-color: $background-color-all-pages;
    border-radius: 0.5rem;

    padding: 1rem;

}

// edit popup
.middle_item_div {
    width: 100%;
    @include flex-column-start-start;
    gap: 0.5rem;
}

.middle_item_select_div {
    width: 100%;
    @include flex-row-start-center;
    padding: 0.5rem 0rem;
    gap: 0.5rem;

}

.middle_copy_div {
    width: 100%;
    @include flex-row-end-center;

}

.middle_copy_div_btn {
    width: 100%;
    @include flex-row-end-center;
    gap: 0.5rem;

    // border: 1px solid red !important;
}

.middle_select_item_label {
    min-width: 50%;
    @include flex-row-start-center;
    gap: 0.5rem;

}

.middle_item_filter_message_div {
    width: 100%;
    @include flex-row-start-center;
    padding: 0.5rem 0rem;
    gap: 0.5rem;
}

.p_div {
    @include flex-row-center-center;
    gap: 0.5rem;

}

.middle_textarea {
    min-width: 43rem;
    min-height: 20rem;

    max-width: 100%;
    max-height: 28rem;

    background-color: white;
    border: 0px;
    border-radius: 0.5rem;
    padding: 0.5rem;

    
}

.filter_buttons {
    @include flex-row-end-center;

    width: 100%;
    gap: 1rem;
}

.cursor {
    cursor: pointer;
}


.clickToCopy {
    text-align: center;
    border: 1px dotted gray;
    border-radius: 1em;
    padding: 0.5em;
    background: #d4d4d4;
    cursor: pointer;
    margin: 0.3em 0px;

}

.clickToCopy:active {
    background: #858181;
}

.code_container_div {
    background: #efefef;
    min-width: 100%;
    min-height: 10em;
    padding: 1em;
  
    color: #4e4c4c;
    margin: 1em 0em;
    cursor: pointer;
    white-space: pre-line;
    max-height: 10em;
}

.table_header {
    color: red;
}

.header_button {
    background-color: transparent;
    margin-left: 108px;
}

.link_button {
    width: 100%;
    @include flex-row-center-center;

}

.green_button{
    max-width: 6rem;
        
    @include popup_btn;
    background-color: white;
    color: $green-primary;
    
    border: 2px solid $green-primary;
    cursor: pointer;
    
    text-align: center;
    transition: background-color 0.3s, transform 0.2s;
}
.orange_button {
    max-width: 6rem;

    @include popup_btn;

    background-color: white;
    color: orange;

    border: 2px solid orange;
    cursor: pointer;

    text-align: center;
    transition: background-color 0.3s, transform 0.2s;
}
.blue_button {
    width: auto;
    max-width: 7rem;

    @include popup_btn;

    background-color: white;
    color: $blue-primary;

    border: 2px solid $blue-primary;
    cursor: pointer;

    text-align: center;
    transition: background-color 0.3s, transform 0.2s;
}


.green_button:hover {
    background-color: $green-primary;
    color: white;
}
.blue_button:hover {
    background-color: $blue-primary;
    color: white;
}
.orange_button:hover {
    background-color: orange;
    color: white;
}




.link {
    background-color: transparent;
}

.lockIcon {
    background-color: transparent;
    display: flex;
    justify-content: center;
}

.unlockIcon {
    background-color: transparent;
    display: flex;
    justify-content: center;
}

.payout_button {
    background-color: transparent;
    margin-left: 6px;
}

.header_name_button {
    background-color: transparent;
    margin-left: 6px;
}

.maintop_container {
    background-color: white;

    gap: 0px !important;

    height: 52px;
    width: auto;
    margin-left: 0px;
    margin-right: 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.first_section {
    flex: 1;
    display: flex;   
}

.second_section {
    width: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    gap: 0.5rem;

    // border: 1px solid red;
}

.maintop_settings_toplabel {
    margin-right: 81px;
}

.maintop_settings {
    @include main-top-container-default;
    padding: 10px;
    gap: 0px !important;
    height: 37px;
    width: 234px;
    margin-left: -10px;

    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $input_background_shade;
    margin-top: 0px !important;
    margin-bottom: 8px;

}

.offer_title {
    margin-right: 0px !important;

    @include flex-row-start-center;
}

.extra_padding{
    padding: 0rem 0.5rem;
}
.extra_padding_top{
    padding-top: 0.5rem;
}
.extra_margin_top{
    width: 100%;
    margin-top: 1.5rem;

    @include flex-column-start-start;
    gap: 1rem;
}


.settings_icon {
    background-color: $input_background;
    padding: 0.3rem;
    margin: 0rem;
    border-radius: 4px;
    height: 2rem;

    margin-right: 1rem;

    & img{
        width: 100%;
        height: 100%;
    }
}
.search_icon{
    border-radius: 4px;
    margin-right: 1rem;

    padding: 0rem 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $input_background;
    gap: 0.5rem;
}

.list_icon {
    background-color: white;
    padding: 6px 10px;
    border: 1px solid #dddfe2;
    height: 33px;
    margin-bottom: 6px;
}

.divider {
    border-right: 1px solid #ccc;
    height: 33px;
    margin: 0 12px;
}

.grid_icon {
    background-color: white;
    padding: 6px 10px;
    border: 1px solid #dddfe2;
    height: 33px;
    margin-bottom: 6px;
}

.search input {
    height: 31px;
    width: 246px;
    border: none;

    background-color: $input_background;
}

.search input:focus {
    outline: none;
    // border-color: #dddfe2;
}


.settings {
    position: fixed;
    top: 130px;
    right: 492px;
    background-color: white;
    padding: 7px 10px;

    width: 236px;
    z-index: 1000;
    padding-top: 0px;

    border-radius: 4px;

    overflow: hidden;
    box-shadow: 0px 0px 100000px #e2eaff;

}

.settings_main {
    cursor: pointer;
}

.settings_headernames {
    list-style-type: none;
}

.settings_list {
    display: flex;
    justify-content: space-between;
}


.settings_applybutton {
    padding: 4px 15px;
    outline: none;

    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
    transform: scale(1.00001);
    line-height: 17px;
    position: relative;
    z-index: 1;

    -webkit-backface-visibility: hidden;
    background-color: #ffb14c;
    border-color: #ffb14c;
    color: white;
    margin-left: 154px;
    margin-bottom: 3px;
}

.custom_check_icon {
    color: white;
}

.offer_icon {
    padding: 1px 7px;
}


.dropdown {
    padding: 2px 1px;
    
}

.dropdown select {
    background-color: white;
    padding: 2px 1px;
    
    border: solid 1px $input_background;
    border-radius: 4px;
    padding: 5px;
}

.label {
    flex: 0 0 auto;

}

.payout_label {
    flex: 0 0 auto;
}

.header {
    display: flex;
    margin-left: 0px !important;
    border: none;
    justify-content: end;

}


.pagination {
    @include main-top-container-default;
    background: white;
    padding: 0.5rem 0rem;
    gap: 0px !important;
    height: 50px;
    width: auto;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 1rem;

    display: flex;
    justify-content: right;

    // border: 1px solid red;
}

.page_number {
    background-color: white;
    padding: 2px 1px;

    border: solid 1px #efefef;
    padding: 5px;
    width: 32px;
}


.active {
    background-color: white;
    padding: 2px 1px;

    border: solid 1px #efefef;
    padding: 5px;
    width: 32px;
}

.popup_item{
    width: 100%;
    @include flex-row-start-start;
    gap: 0.5rem;
    padding: 0.2em 0em;

}

.popup_item_thumbnail_big{
    width: 100%;
    @include flex-row-end-start;
    position: relative;

    & img{
        min-width: 6rem;
        max-width: 6rem;

        min-height: 6rem;
        max-height: 6rem;

        background-size: cover;
        object-fit: contain;
        overflow: hidden;
    }
}

.popup_headers_thumbnail{
    position: absolute;
    right: 0;
    top: 0;

    font-weight: 800;
    
    @include flex-column-start-start;
    gap: 0.5rem;
    padding: 0.8rem;

    border-radius: 0.5rem;
    background-color: white;
    box-shadow: inset 5px -5px 10px 5px rgba(255, 255, 255, 0.2),  0 0 20px 0 $seperator_background;


}

.popup_item_thumbnail{
    width: 100%;
    @include flex-row-start-center;
    
    & img{
        max-width: 100%;
        max-height: 5rem;
    }
}
.popup_headers {
    width: 40%;
    @include flex-row-start-center;

    font-weight: 800;
}

.popup_container {
    width: 550px;
    border-bottom: 1px solid #dddfe2;
    margin-top: 30px;

}

.cancel_button {
    color: $red-primary !important;
    border: 2px solid $red-primary !important;

    padding: 0.3rem 0.5rem;

}
.cancel_button:hover{
    color: white !important;
    background-color: $red-primary !important;

}

.bottom_buttons {
    width: 100%;
    @include flex-row-end-center;
    gap: 0.5rem;

}


.popup_checkbox {
    margin-left: 11px;
}

.popup_values {
    width: 60%;
    white-space: pre-line;
    & a{

        font-weight: 800;
    }

    & a:hover{
        color: $green-primary;
    }
}


.apply_for_run_div{
    width: 100%;
    @include flex-column-start-start;
}
.popup_hr {
    width: 100%;
    margin: 0rem;
    margin-top: 0.8rem;

}
.popup_hr_without_margin {
    width: 100%;
    margin: 0rem;
}

.popup_values_100{
    width: 100%;
    margin-top: 0.5rem;

}
.popup_agree{
    display: flex;
}


.flagTooltip {
    position: relative;
    display: inline-block;

    .tooltipText {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover .tooltipText {
        visibility: visible;
        opacity: 1;
    }
}

.checkbox_label{
    color: rgb(55, 73, 87);
    font-weight:10;
}
.platform_Label{
    font-weight: 700;
    margin-left: 36px;
    display: flex;
    color: rgb(55, 73, 87);

}
.platform_header{
    margin-left:73px;
    color: rgb(55, 73, 87);
    font-weight: 10 !important;

}
.checkbox_sub_label{
    margin-left: 93px;
    
    font-weight: 10;
    color: rgb(55, 73, 87);
}
.checkbox_sub_label_win{
     
    font-weight: 100;
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(55, 73, 87);

}
.checkbox_items{
    color:red;
}
.platform_header_mobile{
      margin-left:181px;
      color: rgb(55, 73, 87);
      margin-top:-8px;
      margin-bottom:0px;
}
.checkbox_mobile{
    
    font-weight: 100;
    color: rgb(55, 73, 87);
    margin-right: 10px;
}
.platform_Label_sub{
   margin-left: 89px;

}
.toggleButton {
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}

.toggleButtonAct {
  background-color: green;
  color: white;
}

.toggleButtonInact {
  background-color: red;
  color: white;
}
.status_toggle{
  margin-left: 97px;

}
.mobile_main{
    color: rgb(55, 73, 87);
    font-weight: 10 !important;
    margin-bottom: 10px;
}
.select_countries{
    color:red;
}
.countries_label{
    width: 48px !important;
    margin-right: 51px;
}
.input_container_payout{
    width: 100% !important;
}


.form{
    @include flex-column-start-start;
    gap: 0.5rem;

    padding: 0.5rem;
}
.popup_content_main{
    width: 100%;
    height: 100%;

    @include flex-row-between-start;
    gap: 1.5rem;
    overflow-y: scroll;

}

.popup_content_left, .popup_content_right{
    width: 100%;
    @include flex-column-start-start;
    gap: 0.5rem;
}

.popup_checkbox_content{
    font-weight: 100;
    margin-top: 0.5rem;
    margin-left: 5px;
}




@media screen and (max-width: 480px) {
    .pagination {
        @include main-top-container-default;
        background: white;
        padding: 10px;
        gap: 0px !important;
        height: 50px;
      
        margin-left: 0px;
        margin-right: 0px;
        display: flex;
        border-top: 0px;
        border-bottom: 1px solid #dddfe2;
        border-left: 1px solid #dddfe2;
        border-right: 1px solid #dddfe2;

        justify-content: right;
        
    }
}

@media screen and (max-width: 375px) {
    .main_middle span{
        text-align: left;
        font-family: "Open Sans";
    }
}

.middle_copy_div_btn button {
    width: auto;
    background-color: #182444;
    color: #FFF;

    font-weight: bold;


}

.red_asterisk {
    color: red;
}


.main_middle span{
    
    text-align: left;

    font-family: "Open Sans";
}
.th_main{
    
}
.th_main_item{
    color:red;
}
.th_label{
    color:red;
}
.tooltip {
    position: relative;
    display: inline-block;
    /* You can adjust the style of the tooltip here */
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}


.cursor_pointer{
    cursor: pointer;
}


@media screen and (max-width: 1600px) {
    /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */
    .header_button {
        background-color: transparent;
        margin-left:83px
    }
    
}  




@media screen and (max-width: 767px) {
    .main {
        width: 100%;
        height: 94dvh !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(255, 255, 255);
        // box-shadow: 0 0 0 3px rgba(222, 225, 229, 0.3);
        position: relative;
        padding: 12px 3px;
        overflow: hidden;

        position: relative;
    }    

    .second_section {
        width: 100%;

        display: flex;
        justify-content: space-between;
        gap: 0rem;
        
        padding: 0.5rem;
        margin-bottom: 1rem;
    }

    .settings {
        min-width: 70vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        & .button_div{
            width: 100%;
            text-align: end;
        }
        
    }
    .settings_icon {
        margin-right: 0rem;
    }
    .search_icon{
        border-radius: 4px;
        margin-right: 0rem;
        gap: 0rem;
    }
    .search input {
        height: 2rem;
        padding: 10px;
        max-width: 12rem;
    }
    .entries{
        display: none;
    }
    .maintop_settings {
        min-width: 70vw;
    }
    .dropdown select {
        background-color: white;
        padding: 2px 1px;
        
        // border: solid 1px $input_background;
        border-radius: 4px;
        padding: 5px;
    }
   
    .main_middle{
        height: 100%;
        min-width: 100vw;
        max-width: 100vw;

        overflow-x: hidden;

        overflow: scroll;

        // border: 2px solid red;
    }
    
    .pagination {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 1rem;
    
        display: flex;
        flex-direction: row;
        justify-content: center;

    }

    .popup_item_thumbnail_big{
        & img{
            min-width: 4rem;
            max-width: 4rem;
    
            min-height: 4rem;
            max-height: 4rem;
        }
    }
} 

// @media screen and (max-width: 480px) {
//    .main {

//         height: 100%;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         background-color: rgb(255, 255, 255);
//         box-shadow: 0 0 0 3px rgba(222, 225, 229, 0.3);
//         position: relative;
//         padding: 12px 3px;
//         overflow-y: hidden;
//     }
//     .main_top {
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         align-items: baseline;
//         margin-bottom: 0.5rem;

//     }
//     .maintop_container {
//         @include main-top-container-default;
//         background: #efefef;
//         padding: 10px;
//         gap: 0px !important;
//         height: 52px;
//         width: auto;
//         margin-left: 0px;
//         margin-right: 0px;
//         display: flex;
//         border: 1px solid #dddfe2;
//         box-shadow: 0 0 0 3px rgba(222, 225, 229, 0.3);

//         align-items: center;
//         justify-content: space-between;

//     }
//     .page_number {
//         width: 100%;
//         height: 5dvh !important;
//         background-color: white;
        
//         border: solid 1px #efefef;
//         padding: 0.5rem !important;
//         // display:none;
//     }    
//  }   

//  @media screen and (max-width: 640px) {

//     .main {
//         height: 100%;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         background-color: rgb(255, 255, 255);
//         box-shadow: 0 0 0 3px rgba(222, 225, 229, 0.3);
//         position: relative;
//         padding: 12px 3px;
//         overflow-y: hidden;
//     }
//     .maintop_container {
//         @include main-top-container-default;
//         background: #efefef;
//         padding: 10px;
//         gap: 0px !important;
//         height: 52px;
//         width: auto;
//         margin-left: 0px;
//         margin-right: 0px;
//         display: flex;
//         border: 1px solid #dddfe2;
//         box-shadow: 0 0 0 3px rgba(222, 225, 229, 0.3);
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         // display:none;
//     }    
// } 


//  @media screen and (max-width: 480px) {

//     .main {
//         height: 100%;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         background-color: rgb(255, 255, 255);
//         box-shadow: 0 0 0 3px rgba(222, 225, 229, 0.3);
//         position: relative;
//         padding: 12px 3px;
//         overflow-y: hidden;
//     }
//     .maintop_container {
//         @include main-top-container-default;
//         background: #efefef;
//         padding: 10px;
//         gap: 0px !important;
//         height: auto;

//         overflow-x: scroll;

//         min-width: 100vw;
//         max-width: 100vw;

//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         border: 1px solid #dddfe2;
//         box-shadow: 0 0 0 3px rgba(222, 225, 229, 0.3);
//         display: flex;
//         // display:none;

//         border: 1px solid red;
//     }    
// } 