@import '../../../assets/styles/variables.module.scss';
@import '../../../assets/styles/mixins.module.scss';

.container {
    width: 100%;
    @include flex-row-between-center;
    max-height: 140px;

    & input, textarea {
        min-width: 70%;
        height: 100%;

        border-style: none;
        outline: none;
        // font-size: 0.9rem;
        color: #374957;
    }
    
    textarea {
        min-width: 70%;
        max-width: 70%;
        text-align: left;
        background: #f7f7f7;
        border-radius: 4px;
        padding: 0.7rem 0.5rem;
        resize: none;
    }

    & span{
        // font-size: 1rem;
    }
}

.icon {
    display: flex;
}

.blue {
    border-color: $blue-primary !important;
    & input {
        color: $blue-primary !important;
    }
}

.red {
    border-color: $red-primary !important;
    & input {
        color: $red-primary !important;
    }
}

.green {
    border-color: $green-primary !important;
   & input {
        color: $green-primary !important;
   }
}