// @import '../../assets/styles/mixins.module.scss';

.container {
    height: 45px;

    display: flex;
    align-items: center;

    gap: 1.5rem;
    padding: 0.5rem 1rem;
    
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        background-color: rgba(255, 255, 255, 0.205);
        border-radius: 0.5rem;
    }
}

.selected {
    height: 45px;

    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
}

.image {
    display: flex;
    & img {
        width: 1.3rem;
    }
}

.name {
    color: white;
    white-space: nowrap;
}


.notification_div{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    
    // border: 1px solid red;
}

.notification{
    background-color: white;
    box-shadow: 0px 0px 0.5rem white;
    padding: 0.3rem 0.8rem;

    font-weight: 800;
    border-radius: 4px;
}