@import '../../../assets/styles/variables.module.scss';
@import '../../../assets/styles/mixins.module.scss';

.container {
    width: 100%;
    @include flex-row-start-center;
    max-height: 50px;

    padding: 1rem 1rem;
    background: #f7f7f7;
    border-radius: 4px;
    gap: 1rem;


    & input {
        width: 100%;
        height: 100%;

        // padding: 1rem 1rem;
        background: #f7f7f7;

        border-style: none;
        outline: none;
        // font-size: 0.9rem;
        color: #374957;
    }

    & span{
        // font-size: 1rem;
    }
}

.icon {
    display: flex;
}

.blue {
    border-color: $blue-primary !important;
    & input {
        color: $blue-primary !important;
    }
}

.red {
    border-color: $red-primary !important;
    & input {
        color: $red-primary !important;
    }
}

.green {
    border-color: $green-primary !important;
   & input {
        color: $green-primary !important;
   }
}