@import '../../../assets/styles/mixins.module.scss';
@import '../../../assets/styles/variables.module.scss';

.container {
    width: 2.4rem;
    height: 2.4rem;

    @include flex-row-center-center;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
   
}

.red {
    background-color: $red-primary;
    box-shadow: 0px 8px 12px rgba(172, 0, 45, 0.3)

}

.blue {
    background-color: $blue-primary;
    box-shadow: 0px 8px 12px rgba(25, 39, 101, 0.3);
}

.green {
    background-color: $green-primary;
}

.black {
    background-color: black;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.3)
}

.yellow {
    background-color: $yellow-primary;
}

.white {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.18rem;
}


.white:hover{
    background-color: rgba(255, 255, 255, 0.205);
    border-radius: 0.5rem;
    padding: 0.18rem;
}