@import '../../assets/styles/mixins.module.scss';
@import '../../assets/styles/variables.module.scss';


.container {
    width: 100%;
    height: 100px;
    @include flex-row-center-center;
    gap: 1rem;
    
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    box-shadow: 0px 0px 100px 0px #e2eaff;
    text-align: center;
    
}
.title {
    color: $blue-primary;
    font-weight: bolder;
    font-size: 0.9rem;
}
.value {
    color: $gray-primary;
    margin-top: 0.2rem;
}
.icon {
    width: 1.5rem;
    height: 1.5rem;

    cursor: pointer;
}


// @media screen and (max-width: 930px) {
//     .container {
//         max-width: unset;
//         min-width: unset;
//         width: 100%;


//     }
// }

// @media screen and (max-width: 350px) {
// }



@media screen and (max-width: 767px) {
    .container{
        @include flex-row-between-center;
        text-align: left !important; 
    }
    .title, .value{
        font-size: 0.9rem;
    }
}
