@import '../../assets/styles/variables.module.scss';

.container {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 0.5rem;
    background-color: $background-color-all-pages;
    color: $gray-primary;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    & input {
        border-style: none;
        outline: none;
        background-color: $background-color-all-pages;
        color: $gray-primary;
        // font-size: 0.9rem;
        width: 90%;
    }
}

@media screen and (max-width: 385px) {
    .container {
        width: 100%;

        & input {
            width: 90%;
            // font-size: 0.9rem;
        }
    }
}