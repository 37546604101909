@import '../../assets/styles/variables.module.scss';
@import '../../assets//styles/mixins.module.scss';


.container {
    @include page-container-default;
    grid-row: 1/3;
    padding: 0rem;

    position: relative;
}
.main {
    width: 100%;
    height: 92vh;
    @include flex-column-start-start;

    background-color: white;
    border-radius: 4px;
    padding: 0.8rem;

    overflow-y: hidden;
    gap: 0.5rem;

    // border: 1px solid red;
}



.main_top {
    @include flex-row-between-center;
    width: 100%;
    max-height: 5%;

    // border: 5px solid green;
}


.main_middle {
    width: 100%;
    height: 95%;

    overflow: hidden;
    @include flex-column-start-start;
    overflow-x: hidden;
    overflow-y: scroll;

    // border: 1px solid cyan;
}

.switch_fragment_div_parent{
    width: 100%;
    height: 5%;
    
    @include flex-row-between-center;
    padding: 0.8rem 0rem;
    gap: 1rem;

}
.switch_fragment_div{
    width: 100%;
    height: 100%;
    
    @include flex-row-start-center;
    gap: 1rem;
}
.export_div{
    width: auto;
    cursor: pointer;

    @include flex-row-center-center;
    gap: 0.5rem;

    background-color: $green-primary;
    border-radius: 4px;
    padding: 0.5rem;

    & img{
        height: 1rem;
    }

    & div{
        color: white;
        font-weight: bolder;
        font-size: 0.9rem;
    }
}
.invalid_revenue_div{
    width: 8rem !important;
    cursor: pointer;

    @include flex-row-center-center;
    gap: 0.5rem;

    background-color: $red-primary;
    border-radius: 4px;
    padding: 0.5rem;

    & img{
        height: 1rem;
    }

    & div{
        color: white;
        font-weight: bolder;
        font-size: 0.9rem;
    }
}

.active_fragment{
    font-weight: bolder;
}
.active_fragment::after{
    margin: 0.3rem 0rem;
    content: "";
    display: block;
    width: 100%;
    height: 0.3rem;
    border-radius: 0.5rem;
    background-color: $blue-primary;
}

.inactive_fragment{
    color: $gray-secondary;
    font-weight: bolder;
    cursor: pointer;
}
.inactive_fragment::after{
    margin: 0.3rem 0rem;
    content: "";
    display: block;
    width: 100%;
    height: 0.3rem;
    border-radius: 0.5rem;
    background-color: white;

}


.main_middle_top {
    width: 100%;
    height: 20%;
    
    @include flex-row-center-center;
    padding: 0.5rem 0rem;
}

.main_middle_bottom{
    width: 100%;
    max-height: 75%;

    @include flex-column-start-start;
    margin-top: 0.5rem;
    gap: 1rem;
}



// admin billing css
.main_middle_admin {
    width: 100%;
    height: 100%;

    @include flex-column-start-start;
    overflow-x: hidden;
    overflow-y: scroll;

    // border: 3px solid cyan;
}


.main_middle_top_admin {
    width: 100%;
    height: 20%;
    
    @include flex-row-center-center;
    padding: 0.5rem 0rem;
    
    // border: 1px solid green;
}
.main_middle_bottom_admin{
    width: 100%;
    height: 100%;

    @include flex-column-start-start;
    margin-top: 0.5rem;
    gap: 1rem;

    overflow-y: scroll;

    // border: 1px solid red;
}
.main_middle_bottom_admin_popup{
    width: 100%;

    min-height: 400px;
    max-height: 82vh;

    @include flex-column-start-start;
    margin-top: 0.5rem;
    gap: 1rem;

    position: relative;
    overflow: hidden;

    // border: 1px solid green;
}




.sectionContainer_row{
    width: 100%;
    height: 100%;

    max-height: 70%;
    @include flex-row-between-start;
    gap: 0.8rem;

    // border: 1px solid green;
}
.sectionContainer_column{
    width: 100%;
    height: 100%;

    max-height: 70%;
    @include flex-column-start-start;
    gap: 0.8rem;

    // border: 1px solid green;
}

.sectionContainer{
    width: 100%;
    height: 100%;

    // max-height: 100%;
    // min-height: 20rem;
    
    @include flex-column-start-start;
    gap: 0.5rem;
    overflow: hidden;
    position: relative;

}
.sectionContainer_top{
    width: 100%;    
    @include flex-row-between-center;
    gap: 0.5rem;
    
    & h6{
        width: 100%;
    }
    
}
.center_spinner_div{
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 1000;

}
.search_select_div_100{
    width: 30%;
    @include flex-row-end-center;
    gap: 0.5rem;
    
    & div{
        width: 100%;
    }
}

.search_select_div{
    @include flex-row-end-center;
    gap: 0.5rem;
    
    & div{
        width: 100%;
    }
}
.invalid_action_div{
    width: 100%;
    @include flex-row-between-center;
    gap: 0.5rem;
}


.date_picker_div{

    & .rs-picker-popup{
        z-index: 10000 !important;
        
        border: 10px solid red !important;
        background-color: blue;
    }
}

.add_payment_mode_div{
    width: 100%;
    @include flex-row-between-center;
}
.delete_payment_method_btn_div{
    width: 100%;
    @include flex-row-end-center;
    gap: 0.3rem;

}
.header{
    width: auto;
    font-size: 1.1rem;
    font-weight: bolder;
    text-align: left;
}

.header_extra_top{
    width: 100%;
    @include flex-row-between-center;
    margin-top: 1rem;
    
    & div{
        font-weight: bolder;
        font-size: 1.1rem;
        text-align: left;
    }
}
.tableContainer {
    width: 100%;
    
    text-align: start;
    overflow: scroll;
}

.local_table_div{
    width: 100%;
    // min-height: 25vh;
    height: 100%;

    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;

    border: 1px solid $input_background;

    & thead th {
        padding: 0.8rem !important;
        background-color: white;
        z-index: 1;
    }
}
.local_table_div_billing{
    width: 100%;
    min-height: 25vh;

    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;

    border: 1px solid $input_background;

    & thead th {
        padding: 0.8rem !important;
        background-color: white;
        z-index: 1;
    }
}


.table_btn{
    margin-top: 0.5rem;
    position: absolute;
    right: 0;

    @include popup_btn;
    color: $blue-primary;
    border: 2px solid $blue-primary;
    background-color: white;
}
.table_btn:hover{
    color: white;
    background-color: $blue-primary;
}

.noData{
    width: 100%;
    height: 100%;
    position: absolute;
    
    top: 50%;
    
    color: $red-primary;
    font-weight: bold;
}
.noDataBilling{
    width: 100%;
    height: 100%;
    position: absolute;
    
    top: 60%;
    left: 0%;
    
    color: $red-primary;
    font-weight: bold;
}

.main_bottom {
    padding: 0.5rem 0rem;
    @include flex-row-end-center
}


.main_top_extra_padding {
    @include flex-row-between-center;
    width: 100%;
    margin: 1rem 0rem;
    @include gray-bottom-border;
}


.main_title {
    width: auto;
    @include page-title;
    @include flex-row-start-center;
    gap: 1rem;

    .heading {
        font-size: 1.5rem !important;
        font-weight: bold;
    }
}
.main_title_custom {
    width: auto;
    @include page-title;
    @include flex-row-start-center;
    cursor: pointer;
    
    & img{
        width: 2.2rem;
        height: 2rem;
    }

    .heading {
        font-size: 1.5rem !important;
        font-weight: bold;
    }
}

.main_title_secondary{
    @include flex-row-center-center;
    @include page-title-secondary;
}

.items_selected_div{
    font-weight: bold;
    color: $gray-primary;
}


.main_search {
    @include flex-row-between-center;
    width: 50%;
    gap: 1rem;
}

.main_operation {
    @include flex-row-between-center;
    gap: 1rem;
}

.customButton {
    width: 100%;

    @include flex-row-center-center;
    gap: 0.5rem;
}



.filter {
    display: flex;
    gap: 1rem;
}

.filter_checkboxes {
    display: flex;
    gap: 1rem;
    border-style: solid;
    border-width: 2px;
    border-color: $blue-primary;
    padding: 0rem 2rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter_checkboxes_item {
    font-weight: bold;
    color: $blue-primary;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}


.go_back_btn_div{
    cursor: pointer;
    text-decoration: underline;
}

.go_back_btn_div:hover{
    color: $red-primary;
    cursor: pointer;
    text-decoration: underline;
}

.go_back_btn{
    padding: 0rem 0.5rem;
}


.td_underline{
    text-decoration: underline !important;
    cursor: pointer;
    font-weight: bold;
}

.td_underline:hover{
    color: $red-primary;
}


.action_tab_buttons{
    @include flex-row-center-center;
    gap:3rem;
    height: auto;
    width: 100%;
}


.input_date{
    background-color: #f3f2f2;
    color: $color-foreshop;
    @include gray-bottom-border;
    padding:1rem;

    border-radius: 0.5rem;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    border-width: 1px;
}

.input_date:focus{
    border-color: rgb(211, 211, 211);

}

.action_button_each{
    cursor: pointer;
}

.action_button_each_inactive{
    cursor: progress;
}

.action_button_each_send_now{
    cursor: pointer;
    width: 12%;
    height: auto;
}

.action_button_each_send_now_sending{
    cursor: progress;
    width: 12%;
    height: auto;
}

.action_button_each_website{
    cursor: pointer;
    padding: 0rem .5rem;
    margin: 0rem 1rem
}

.no_data_found_div{
    @include flex-row-center-center;
    font-weight: bold;
    color: $red-primary;
    margin: 1rem;
    padding: 1rem;
}


a:link {
    color: $blue-primary;
    text-decoration: none;
  }
  
  a:visited {
    color: $blue-primary;;
    text-decoration: none;
  }
  
  a:hover {
    color: $red-primary;;
    text-decoration: none;
  }




  .border{
    border: 1px solid red;
  }

//   ############################# new css #########################
.form_div_no_padding{
    width: 100%;

    @include flex-column-between-start;
    gap: 0.8rem;

    & label{
        width: 30%;
    }

    & select, input{
        width: 70%;
        border: 1px solid $input_background;
        border-radius: 4px;
        padding: 0.5rem;
    }
}
.form_div{
    width: 100%;

    @include flex-column-between-start;
    gap: 0.8rem;
    padding: 0rem 0.5rem;

    & label{
        width: 40%;
    }

    & select, input{
        width: 65%;
        border: 1px solid $input_background;
        border-radius: 4px;
        padding: 0.5rem;
    }
}
.form_div_top{
    width: 100%;

    @include flex-column-start-start;
    gap: 0.5rem;

    overflow-y: scroll;
}
.form_child_div{
    width: 100%;
    @include flex-row-start-center;
    gap: 1rem;

    & div{
        width: 100%;
        @include flex-row-start-center;
    }
    & select{
        outline: none;
    }
}
.form_select_div{
    width: 50%;
    padding-right: 0.5rem;
    @include flex-row-start-center;
    
    & div{
        width: 100%;
        @include flex-row-start-center;
    }
    & select{
        outline: none;
    }
}

.popup_content_div{
    width: 100%;
    min-height: 350px;
    @include flex-column-between-center;
    padding: 0.5rem;
}
.popup_content_div_top{
    width: 100%;
    @include flex-column-start-start;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    // border: 1px solid red;
}
.popup_content_table_div{
    min-width: 100%;
    max-height: 30vh;

    @include flex-column-start-start;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    overflow-y: scroll;

    & thead th {
        padding: 0.8rem !important;
        background-color: white;
        z-index: 1;
    }
}
.popup_content_main{
    margin-bottom: 0.5rem;
}
.form_info_div{
    width: 100%;
    @include flex-row-between-center;
    gap: 0.8rem;
}
.form_info_div_each{
    width: 100%;
    @include flex-row-start-start;
    gap: 0.5rem;   

    & label{
        width: 10%;
    }
    & div{
        width: 90%;
        overflow-x: scroll;
    }

    .label_50{
        width: 50%;
        font-weight: bold;

        // border: 1px solid red;
    }

}
.all_info_div{
    width: 100%;
    @include flex-column-start-start;
    gap: 0.5rem;


}
.all_info_div_each{
    min-width: 100%;
    @include flex-row-start-start;
    gap: 0.3rem;
}
.all_info_div_each_50{
    width: 100%;
    @include flex-row-start-start;
    gap: 0.3rem;
}
.label{
    min-width: 5rem;
    width: auto !important;
    font-weight: bold;
    
}
.value{
    width: 100%;
}

.form_info_div_each_row_div{
    @include flex-row-between-center;

}
.form_info_div_icon{
    max-height: 3rem;
    @include flex-column-start-start;

}
.btn_end_div{
    width: 100%;
    height: auto;
    @include flex-row-end-center;

    & img{
        padding: 0.5rem 0rem 0.5rem 1rem;
    }
}
.btn_end_div_absolute{
    position: absolute;
    width: 100%;
    height: auto;
    @include flex-row-end-center;
    gap: 0.5rem;

    bottom: 0rem;
    right: 0.5rem;

    & img{
        max-width: 3rem;
        max-height: 3rem;
    }
}

.payment_method_logo{
    padding: 0rem !important;
    margin: 0rem 0.5rem;

    max-width: 2rem;
    max-height: 2rem;
}


.btn_div{
    width: 100%;
    height: auto;
    @include flex-row-center-center;
    gap: 0.5rem;
}
.btn_div_right_align{
    width: 100%;
    height: auto;
    @include flex-row-center-center;
    gap: 0.5rem;
}
.default_btn{
    @include popup_btn;
    color: $blue-primary;
    border: 2px solid $blue-primary;
    background-color: white;
}
.default_btn:hover{
    color: white;
    background-color: $blue-primary;
}
.default_red_btn{
    @include popup_btn;
    color: $red-primary;
    border: 2px solid $red-primary;
    background-color: white;
}
.default_red_btn:hover{
    color: white;
    background-color: $red-primary;
}
.default_orange_btn{
    @include popup_btn;
    color: orange;
    border: 2px solid orange;
    background-color: white;
}
.default_orange_btn:hover{
    color: white;
    background-color: orange;
}
.default_green_btn{
    @include popup_btn;
    color: $green-primary;
    border: 2px solid $green-primary;
    background-color: white;
}
.default_green_btn:hover{
    color: white;
    background-color: $green-primary;
}

.default_orange_red_btn{
    @include popup_btn;
    background-color: white;
    border: 2px solid orangered;
    color: orangered;
}
.default_orange_red_btn:hover{
    background-color: orangered;
    color: white;
}



// ############################# payment method button #########################   
.paypal{
    font-weight: bold;
    color: #009cde;
}
.payoneer{
    font-weight: bold;
    color: #DA54D8;
}
.bank{
    color: purple;
    font-weight: bold;
}
.green_text{
    color: $green-primary !important;
    font-weight: bold;
}
.orange_text{
    color: orange !important;
    font-weight: bold;
}
.red_text{
    color: $red-primary !important;
    font-weight: bold;
}
.blue_text{
    color: $blue-primary !important;
    font-weight: bold;
}
.orange_red_text{
    color: orangered !important;
    font-weight: bold;
}
.warning_text{
    font-weight: bold;
    color: $red-primary;
}
















@media screen and (max-width: 767px) {
    .main_title{
        width: 100%;

        @include flex-row-between-center;
    }
    .main_operation {
        width: 100%;
        @include flex-row-between-center;

        gap: 1rem !important;
    }
    .customButton {
        @include flex-row-between-center;
    }
    .btn_div{
        width: 100%;
        @include flex-row-center-center;
        gap: 0.5rem;
    }
    .header_extra_top{
        margin-top: 0.5rem;
    }


    .form_child_div{
        @include flex-column-start-start;
    }
    .form_select_div{
        width: 100%;
    }
    .btn_div_right_align{
        width: 100%;
    }


    .main_middle_top{
        height: 100%;
        @include flex-column-start-center;
        gap: 0.5rem;
    }

    // admin billing css
    .main_middle_admin {
        @include flex-column-start-start;
        overflow-x: hidden;
        overflow-y: scroll;

        // border: 3px solid cyan;
    }
    .main_middle_top_admin{
        height: 100%;
        @include flex-column-start-center;
        gap: 0.5rem;
    }
    .main_middle_bottom_admin{
        height: 100%;
        min-height: 80%;
    
        @include flex-column-start-start;
        margin-top: 0.5rem;
        gap: 1rem;
    
        overflow-y: scroll;

        // border: 1px solid red;
    }


    .sectionContainer_row{
        max-height: 100%;
        @include flex-column-start-center;
    }

    .sectionContainer_top{
        height: auto;
        @include flex-column-start-center;
    }
    .search_select_div{
        width: 100%;

        & select{
            width: 100%;
        }

    }




    .sectionContainer_70{
        height: 70%;
        max-height: 70%;

        // border: 1px solid red;
    }
    .sectionContainer_50{
        height: 50%;
        max-height: 50%;

        // border: 1px solid red;
    }
    .sectionContainer_30{
        height: 30%;
        max-height: 30%;

        // border: 1px solid blue;
    }
    .noData{
        top: 60%;
    }
}