@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.container {
    @include page-container-default;
    grid-row: 1/3;
    padding: 0rem;
}

.main {
    height: 100%;
    @include flex-column-between;

    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;

    padding: 1rem;

    gap: 0.5rem;

}

.main_top{
    @include flex-row-between-center;
}

.main_title {
    @include page-title
}



.main_middle {
    height: 100%;
    @include flex-column-start-center;

}

.main_middle_top{
    width: 100%;
}

.main_middle_bottom{
    width: 100%;
}

.main_middle_title{
    // font-size: 0.9rem;
    padding:0.5rem 0rem;
}



.main_middle_code_div{
    width: 100%;
    @include flex-column-start-start;
    gap: 0.5rem;
}


.main_middle_code{
    width: 100%;
    height: 100%;

    overflow: scroll;

    background-color: rgb(255, 255, 255);


    // code text formatting
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1em;
}


.main_middle_edit{
    width: 100%;
    @include flex-row-end-center;
    gap: 0.5rem;
}



.edit_code_buttons{
    width: 10%;
}




.main_middle_edit_div{
    @include flex-column-center-center;
    background-color: $background-color-all-pages;
}

// edit popup
.middle_item_div{
    width: 100%;
    @include flex-column-start-start;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.middle_item_label_div{
    @include flex-row-start-center;
    gap: 0.5rem;
    padding: 0.5rem 0.5rem;

}
.middle_item_label{
    // font-size: 0.9rem;
}

.middle_item_input_div{
    @include flex-row-center-center;
    width: 100%;
}

.middle_textarea{
    min-width: 43rem;
    min-height: 20rem;

    max-width: 100%;
    max-height: 28rem;
    
    background-color: white;

    font-family: "Open Sans";
    // font-size: 0.9rem;

}

.textarea_code {
    width: 100%;
    height: 20rem;


    resize: none;
    padding: 0.8rem;
    line-height: 1.5rem;


    border: 2px solid $seperator_background;
    border-radius: 4px;
}
